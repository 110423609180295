import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const ubiquiaAxios = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('nfl-ubiquia-access-token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
};

const authErrorRetryHandler = async (error: AxiosError) => {
  const originalRequest = error.config!;

  // If the error status is 401 and there is no originalRequest._retry flag,
  // it means the token has expired and we need to refresh it
  //@ts-ignore
  if (error.response.status === 401 && !originalRequest._retry) {
    // @ts-ignore
    originalRequest._retry = true;

    try {
      const refreshToken = localStorage.getItem('nfl-ubiquia-refresh-token');
      const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/v1/refresh/`, {
        refresh_token: refreshToken,
      });
      const { access_token, refresh_token } = response.data;

      localStorage.setItem('nfl-ubiquia-access-token', access_token);
      localStorage.setItem('nfl-ubiquia-refresh-token', refresh_token);

      // Retry the original request with the new token
      originalRequest.headers.Authorization = `Bearer ${access_token}`;
      return await axios(originalRequest);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      window.location.href = '/logout';
    }
  }

  return Promise.reject(error);
};

// Add a request interceptor
// @ts-ignore
ubiquiaAxios.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));

// Add a response interceptor
// @ts-ignore
ubiquiaAxios.interceptors.response.use((response) => response, authErrorRetryHandler);

export default ubiquiaAxios;
