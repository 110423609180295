import { Navigate, createFileRoute } from '@tanstack/react-router';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useAppStore } from 'stores/appStore';

export const Logout = () => {
  const { logout } = useAuth();
  const setUserEmail = useAppStore((state) => state.setUserEmail);
  const setIsAdmin = useAppStore((state) => state.setIsAdmin);
  useEffect(() => {
    const performLogout = async () => {
      setUserEmail('');
      setIsAdmin(false);
      await logout();
    };
    performLogout();
  }, []);

  return <Navigate to="/login" />;
};

export const Route = createFileRoute('/logout')({
  component: Logout,
});
