import { useEffect, useMemo, useState } from 'react';

import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';

import { useAppStore } from 'stores/appStore';
import { AnalysisTableRow } from 'utils/scheduleConsts';
import { getAnalysisTableRows } from 'utils/scheduleUtils';
import { Box, Text } from '@mantine/core';

import './AnalysisTable.css';

export function AnalysisTable() {
  const [currentAnalysisIndex] = useAppStore((state) => [state.currentAnalysisIndex]);
  const [analysisTableRows, setAnalysisTableRows] = useState<AnalysisTableRow[]>([]);

  useEffect(() => {
    if (currentAnalysisIndex) {
      setAnalysisTableRows(getAnalysisTableRows(currentAnalysisIndex));
    } else {
      setAnalysisTableRows([]);
    }
  }, [currentAnalysisIndex]);

  const columns = useMemo<MRT_ColumnDef<AnalysisTableRow>[]>(
    () => [
      {
        accessorKey: 'section',
        header: 'Section',
        GroupedCell: ({ cell, row }) => (
          <Box h={30} display="flex" style={{ alignItems: 'center' }}>
            <Text size="xs" fw={700} pl="xs" style={{ whiteSpace: 'nowrap' }}>
              {String(cell.getValue())}{' '}
              {row.subRows && row.subRows.length > 1 && `(${row.subRows.length})`}
            </Text>
          </Box>
        ),
        size: 100,
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ],
    []
  );

  const analysisTable = useMantineReactTable({
    data: analysisTableRows,
    columns,
    layoutMode: 'grid',
    enableColumnActions: false,
    enableColumnDragging: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableColumnFilterModes: false,
    enableGrouping: true,
    enableStickyHeader: true,
    memoMode: 'cells',
    // enableStickyHeader: true,
    mantineExpandButtonProps: {
      size: 'xs',
    },
    mantineExpandAllButtonProps: {
      size: 'xs',
    },
    positionToolbarAlertBanner: 'none',
    // enableExpanding: false,
    // enableCollapsing: false,
    // enableExpandAll: false,
    initialState: {
      // @ts-expect-error overriding density
      density: 0,
      grouping: ['section'],
      expanded: true,
      columnVisibility: {
        'mrt-row-expand': false,
      },
    },
    enablePagination: false,
    mantineTableContainerProps: ({ table }) => ({
      style: { height: table.getState().isFullScreen ? '100%' : 'calc(100vh - 260px)' },
    }),
    // enableStickyHeader: true,
    enableRowVirtualization: true,
    enableBottomToolbar: false,
    mantineTableProps: {
      striped: true,
    },
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Button
    //     onClick={() => {
    //       handleExportRows(table.getPrePaginationRowModel().rows);
    //     }}
    //     leftSection={
    //       <FontAwesomeIcon
    //         style={{ width: rem(12), height: rem(12) }}
    //         icon={faArrowDownToBracket}
    //       />
    //     }
    //     variant="default"
    //     size="xs"
    //   >
    //     Download CSV
    //   </Button>
    // ),
  });

  return <MantineReactTable table={analysisTable} />;
}
