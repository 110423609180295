import {
  Box,
  Container,
  Flex,
  Select,
  TextInput,
  Stack,
  Divider,
  Textarea,
  NumberInput,
  MultiSelect,
} from '@mantine/core';
import { CONSTRAINT_PENALTY, ConstraintPenalty, CoreConstraintEntry } from 'utils/constraintConsts';
import { TEAM_KEYS, TeamType } from 'utils/scheduleConsts';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const ALL_MATCHUPS = TEAM_KEYS.flatMap((homeTeam) =>
  TEAM_KEYS.filter((awayTeam) => awayTeam !== homeTeam)
    .map((awayTeam) => `${awayTeam}-${homeTeam}`)
    .sort()
);

const CoreConstraintEditorEntry = ({
  constraint,
  onChange,
}: {
  constraint: CoreConstraintEntry;
  onChange: (value: CoreConstraintEntry) => void;
}) => (
  <Stack>
    <Flex gap="xl">
      <TextInput
        variant="filled"
        w="240"
        description="Name"
        value={constraint.name}
        inputWrapperOrder={['input', 'description']}
      />
      <Textarea
        variant="filled"
        w="400"
        description="Description"
        value={constraint.description}
        inputWrapperOrder={['input', 'description']}
      />
      <TextInput
        variant="filled"
        w="140"
        description="Cateogry"
        value={constraint.category.charAt(0).toUpperCase() + constraint.category.slice(1)}
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
    <Divider />
    <Flex gap="xl">
      <Select
        w="120"
        withCheckIcon={false}
        description="Hard"
        inputWrapperOrder={['input', 'description']}
        allowDeselect={false}
        data={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        value={constraint.hard ? 'true' : 'false'}
        onChange={(value) => onChange({ ...constraint, hard: value === 'true' })}
      />
      <NumberInput
        w="80"
        description="Max"
        value={constraint.max}
        onChange={(e) => {
          const value = Number(e);
          if (value >= 0) {
            onChange({ ...constraint, max: value });
          }
        }}
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="120"
        withCheckIcon={false}
        allowDeselect={false}
        value={constraint.penalty}
        onChange={(penalty) =>
          onChange({
            ...constraint,
            penalty: penalty as ConstraintPenalty,
          })
        }
        data={CONSTRAINT_PENALTY.map((entry) => ({
          label: entry.charAt(0).toUpperCase() + entry.slice(1),
          value: entry,
        }))}
        description="Penalty"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        w="120"
        searchable
        value={constraint.team_exceptions}
        onChange={(team_exceptions) =>
          onChange({
            ...constraint,
            team_exceptions: team_exceptions as TeamType[],
          })
        }
        data={TEAM_KEYS}
        description="Team exceptions"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        w="140"
        searchable
        value={constraint.matchup_restrictions.map((matchup) => `${matchup.away}-${matchup.home}`)}
        onChange={(matchup_restrictions) =>
          onChange({
            ...constraint,
            matchup_restrictions: matchup_restrictions.map((matchup) => {
              const [away, home] = matchup.split('-');
              return {
                away: away as TeamType,
                home: home as TeamType,
              };
            }),
          })
        }
        data={ALL_MATCHUPS}
        description="Matchup restrictions"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  </Stack>
);

export function CoreConstraintEditor({
  value,
  onChange,
}: {
  value: CoreConstraintEntry | null;
  onChange: (value: CoreConstraintEntry) => void;
}) {
  return (
    <Box>
      <Container size="xl">
        {value ? <CoreConstraintEditorEntry constraint={value} onChange={onChange} /> : null}
      </Container>
    </Box>
  );
}
