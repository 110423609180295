import { BroadcastWindowConstraint, CoreConstraintEntry } from './constraintConsts';
import { HolidaySlot } from './scheduleConsts';

export const DEFAULT_BROADCAST_WINDOW_CONSTRAINTS: BroadcastWindowConstraint[] = [
  {
    name: 'CBS Regional',
    network: 'CBS',
    day: 'sun',
    start_time: '13:00',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 3,
    max_games: 6,
    slot_type: 'CBS Early',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'CBS Single Early',
    network: 'CBS',
    day: 'sun',
    start_time: '13:00',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 3,
    max_games: 6,
    slot_type: 'CBS Early',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'CBS Single Late',
    network: 'CBS',
    day: 'sun',
    start_time: '16:05',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 3,
    slot_type: 'CBS Late',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'CBS Doubleheader',
    network: 'CBS',
    day: 'sun',
    start_time: '16:25',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: ['2024-12-15', '2025-01-05'],
    min_games: 1,
    max_games: 3,
    slot_type: 'CBS Late',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'Fox Regional',
    network: 'Fox',
    day: 'sun',
    start_time: '13:00',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 3,
    max_games: 6,
    slot_type: 'Fox Early',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'Fox Single Early',
    network: 'Fox',
    day: 'sun',
    start_time: '13:00',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 3,
    max_games: 6,
    slot_type: 'Fox Early',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'Fox Single Late',
    network: 'Fox',
    day: 'sun',
    start_time: '16:05',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 3,
    slot_type: 'Fox Late',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'Fox Doubleheader',
    network: 'Fox',
    day: 'sun',
    start_time: '16:25',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: ['2024-12-15', '2025-01-05'],
    min_games: 1,
    max_games: 3,
    slot_type: 'Fox Late',
    international: false,
    holiday: undefined,
    locked: true,
  },
  {
    name: 'NBC Season Opener',
    network: 'NBC',
    day: 'thu',
    start_time: '20:15',
    start_date: '2024-09-05',
    end_date: '2024-09-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NBC',
    international: false,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'NBC SNF',
    network: 'NBC',
    day: 'sun',
    start_time: '20:15',
    start_date: '2024-09-08',
    end_date: '2025-01-05',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NBC',
    international: false,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'MNF',
    network: 'ESPN',
    day: 'mon',
    start_time: '20:15',
    start_date: '2024-09-09',
    end_date: '2024-12-30',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'ESPN',
    international: false,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'MNF Double',
    network: 'ESPN',
    day: 'mon',
    start_time: '20:15',
    start_date: '2024-09-23',
    end_date: '2024-10-07',
    off_dates: ['2024-09-30'],
    guarantee_dates: [],
    min_games: 2,
    max_games: 2,
    slot_type: 'ESPN',
    international: false,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'TNF Prime',
    network: 'Amz',
    day: 'thu',
    start_time: '20:20',
    start_date: '2024-09-12',
    end_date: '2024-12-26',
    off_dates: ['2024-11-28'],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'Prime',
    international: false,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'Thanksgiving Early',
    network: 'CBS',
    day: 'thu',
    start_time: '12:30',
    start_date: '2024-11-28',
    end_date: '2024-11-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'CBS Early',
    international: false,
    holiday: HolidaySlot.TGIV,
    locked: false,
  },
  {
    name: 'Thanksgiving Late',
    network: 'Fox',
    day: 'thu',
    start_time: '16:30',
    start_date: '2024-11-28',
    end_date: '2024-11-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'Fox Late',
    international: false,
    holiday: HolidaySlot.TGIV,
    locked: false,
  },
  {
    name: 'Thanksgiving Prime',
    network: 'NBC',
    day: 'thu',
    start_time: '20:20',
    start_date: '2024-11-28',
    end_date: '2024-11-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NBC',
    international: false,
    holiday: HolidaySlot.TGIV,
    locked: false,
  },
  {
    name: 'Christmas Early',
    network: 'Netflix',
    day: 'wed',
    start_time: '13:00',
    start_date: '2024-12-25',
    end_date: '2024-12-25',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'Netflix',
    international: false,
    holiday: HolidaySlot.CHRISTMAS,
    locked: false,
  },
  {
    name: 'Christmas Late',
    network: 'Netflix',
    day: 'wed',
    start_time: '16:30',
    start_date: '2024-12-25',
    end_date: '2024-12-25',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'Netflix',
    international: false,
    holiday: HolidaySlot.CHRISTMAS,
    locked: false,
  },
  {
    name: 'Brazil',
    network: 'Peacock',
    day: 'fri',
    start_time: '20:15',
    start_date: '2024-09-13',
    end_date: '2024-09-13',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'Peacock',
    international: true,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'London',
    network: 'NFLN',
    day: 'sun',
    start_time: '9:30',
    start_date: '2024-10-06',
    end_date: '2024-10-20',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'International',
    international: true,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'Munich',
    network: 'NFLN',
    day: 'sun',
    start_time: '9:30',
    start_date: '2024-11-10',
    end_date: '2024-11-10',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'International',
    international: true,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'NFLN Saturday Early',
    network: 'NFLN',
    day: 'sat',
    start_time: '13:00',
    start_date: '2024-12-28',
    end_date: '2024-12-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NFLN',
    international: true,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'NFLN Saturday Late',
    network: 'NFLN',
    day: 'sat',
    start_time: '16:00',
    start_date: '2024-12-28',
    end_date: '2024-12-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NFLN',
    international: true,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'NFLN Saturday Prime',
    network: 'NFLN',
    day: 'sat',
    start_time: '20:15',
    start_date: '2024-12-28',
    end_date: '2024-12-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NFLN',
    international: true,
    holiday: undefined,
    locked: false,
  },
  {
    name: 'Beijing',
    network: 'NFLN',
    day: 'thu',
    start_time: '20:15',
    start_date: '2024-12-28',
    end_date: '2024-12-28',
    off_dates: [],
    guarantee_dates: [],
    min_games: 1,
    max_games: 1,
    slot_type: 'NFLN',
    international: true,
    holiday: undefined,
    locked: false,
  },
];

export const DEFAULT_CORE_CONSTRAINTS: CoreConstraintEntry[] = [
  {
    name: 'LA home team restriction',
    description:
      'LA cannot have home games in the same week (LAC-LAR), unless the games are more than a day apart (e.g. Thursday and Sunday) or if one of the games is MNF',
    category: 'city',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [{ away: 'LAC', home: 'LAR' }],
  },
  {
    name: 'NYC home team restriction',
    description:
      'NYC cannot have home games in the same week (NYG-NYJ), unless the games are more than a day apart (e.g. Thursday and Sunday) or if one of the games is MNF',
    category: 'city',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [{ away: 'NYG', home: 'NYJ' }],
  },
  {
    name: 'Only one NY/LA team per broadcast window',
    description:
      'NY/LA cannot play in the same broadcast window (e.g. NYG-NYJ or LAC-LAR cannot both play at 1pm on CBS in week 9)',
    category: 'city',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Divisional opponents must play in week 18',
    description: 'Each team must play a division opponent in week 18',
    category: 'competition',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Divisional opponents min 3 weeks apart',
    description: "Two opponents can't play each other twice within a 3-week period",
    category: 'competition',
    hard: false,
    max: 2,
    penalty: 'high',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Divisional opponents only once in first half of season',
    description: 'Two opponents can only play once in the first half of the season',
    category: 'competition',
    hard: false,
    max: 6,
    penalty: 'low',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Max 1 international appearance per team',
    description: 'Team cannot have more than 1 international game',
    category: 'exposure',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: ['JAC'],
    matchup_restrictions: [],
  },
  {
    name: 'Super Bowl winner plays season opener at home',
    description: 'Super Bowl winner must play at home in Game 1 of the season',
    category: 'exposure',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [
      { away: 'BAL', home: 'KC' },
      { away: 'HOU', home: 'KC' },
      { away: 'LAC', home: 'KC' },
    ],
  },
  {
    name: 'No back-to-back appearances on a primetime network',
    description: 'Team cannot play on a primetime network in back-to-back weeks',
    category: 'exposure',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: ['JAC'],
    matchup_restrictions: [],
  },
  {
    name: 'Max 3 consecutive primetime appearances',
    description: 'Team cannot have 4 weeks in a row playing in a national broadcast primetime slot',
    category: 'exposure',
    hard: false,
    max: 2,
    penalty: 'high',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Thursday after Thanksgiving',
    description:
      'Thursday the week after Thanksgiving has to include two teams that played on Thanksgiving',
    category: 'exposure',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Black Friday team restriction',
    description:
      'Team cannot play on Black Friday (week 13 Friday) and on Thursday between weeks 10-15',
    category: 'exposure',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Divisional opponents not on same primetime network',
    description:
      'Two opponents cannot have both of their games appear on the same primetime broadcast network (including Doubleheader)',
    category: 'exposure',
    hard: false,
    max: 2,
    penalty: 'high',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'CBS/Fox doubleheader allocation',
    description: "Each of CBS, Fox must have 8 doubleheaders, can't have more than 2 in a row",
    category: 'network',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'CBS/Fox team allocation',
    description:
      'Each AFC team must appear on CBS 7 times (same for NFC<->Fox); certain teams (hard-coded) must appear 8 times',
    category: 'network',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Detroit and Dallas play on Thanksgiving',
    description: 'Thanksgiving daytime games must include DET and DAL home games',
    category: 'network',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: "PST/MST teams can't play at home before 4pm",
    description: 'Teams in PST/MST cannot play at home in early windows',
    category: 'network',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'International Week +1',
    description: 'Teams should have a home game or bye week after an international game',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: ['JAC'],
    matchup_restrictions: [],
  },
  {
    name: 'International Week -1',
    description: 'Team should not play on MNF before international',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'International Week -2',
    description: 'Teams should not have 2 away games before international game',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No consecutive Thursdays/Fridays',
    description:
      'Team cannot play on back-to-back Thursdays/Fridays (i.e. any two-week sequence can include at most one Thursday/Friday game); exception of week-after-Thanksgiving',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Christmas rest restriction',
    description:
      'The four teams playing on Christmas (week 17 Wednesday) must be the same four teams that played on Saturday before Christmas (week 16 Saturday)',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Road before Black Friday',
    description: 'Away team on Black Friday cannot play Away on week 12',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No traveling more than 2 timezones for Thursday',
    description:
      "Team cannot travel more than 2 timezones for a Thursday game (*if they're already away from home then use their 'current')",
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No 4 Away or 4 Home streaks',
    description:
      'Team cannot have a 4 game home or away streak. Note that streak can also be broken with a BYE week)',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: '1 Home every 4',
    description: 'Team must have at least 1 home game in every 4 weeks',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: '2 Home every 7',
    description: 'Team must have at least 2 home games in every 7 weeks',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: '2 Home every 6',
    description: 'Team must have at least 2 home games in every 6 weeks',
    category: 'rest',
    hard: false,
    max: 8,
    penalty: 'med',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No 2A at end',
    description: 'Each team must play at home at least once in weeks 17/18',
    category: 'rest',
    hard: false,
    max: 6,
    penalty: 'high',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No 2A at start',
    description: 'Each team must play at home at least once in weeks 1/2',
    category: 'rest',
    hard: false,
    max: 6,
    penalty: 'low',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No 3A',
    description: 'No 3 Away',
    category: 'rest',
    hard: false,
    max: 6,
    penalty: 'med',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No 3A for special cases',
    description:
      'No 3 Away to start the season. No 3A to end the season. No 3 a for specific teams that had a 3A last year (*this could be handled via team restrictions).',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Team can have at most one of {3A, 2A start, 2A finish}',
    description: '',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Max 2 appearances on Thursday/Friday',
    description: 'Team cannot play > 2 times on Thursday/Friday',
    category: 'rest',
    hard: false,
    max: 4,
    penalty: 'high',
    team_exceptions: ['KC'],
    matchup_restrictions: [],
  },
  {
    name: 'No BYE two weeks after THURSDAY (weeks 3-6)',
    description:
      'Team cannot have a bye week two weeks after a Thursday night appearance (only valid for Thursdays in week 3-6)',
    category: 'rest',
    hard: true,
    max: 0,
    penalty: 'none',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'No Thursday on either side of Bye',
    description:
      'Team cannot have bye week on either side of Thursday night appearance because they would have extra rest',
    category: 'rest',
    hard: false,
    max: 2,
    penalty: 'low',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Road before road Thursday',
    description: 'Team cannot play Away in week preceding Thursday/Friday Away',
    category: 'rest',
    hard: false,
    max: 4,
    penalty: 'low',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Thursday/Friday after SNF/MNF',
    description: 'Team cannot play SNF/Monday one week and Thursday/Friday next week',
    category: 'rest',
    hard: false,
    max: 4,
    penalty: 'low',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Monday after Thursday/Friday',
    description: 'Team cannot play Thursday/Friday one week and Monday next week',
    category: 'rest',
    hard: false,
    max: 4,
    penalty: 'low',
    team_exceptions: [],
    matchup_restrictions: [],
  },
  {
    name: 'Road after road Monday',
    description:
      'Team cannot play Away in week following Monday Away (may happen at most once per team and at most 6 times in total)',
    category: 'rest',
    hard: false,
    max: 6,
    penalty: 'med',
    team_exceptions: [],
    matchup_restrictions: [],
  },
];
