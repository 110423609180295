import {
  ConstraintSlot,
  DayType,
  HolidaySlot,
  NetworkTypeSimple,
  NetworkTypeSimpleExtended,
  PercentageThresholdsIndex,
  SlotType,
  TeamType,
  WeekExtendedType,
  WeekType,
} from './scheduleConsts';

export type BroadcastWindowConstraint = {
  name: string;
  network: NetworkTypeSimple;
  day: DayType;
  start_time: string;
  start_date: string;
  end_date: string;
  off_dates: string[];
  guarantee_dates: string[];
  min_games: number;
  max_games: number;
  slot_type: SlotType;
  international: boolean;
  holiday?: HolidaySlot;
  locked: boolean; // Determines whether the `name`, `network`, `day`, and `start_time` are locked;
};

export type OptimalContextPayload = {
  user: string;
  name: string;
  context: {
    baseBuildIds: string[];
    warm_start_id: string;
    percentageThresholds: PercentageThresholdsIndex;
    broadcast_windows: BroadcastWindowConstraint[];
    core_constraints: CoreConstraintEntry[];
    stadium_block_constraints: StadiumBlockConstraint[];
    matchup_constraints: MatchupConstraint[];
    team_constraints: TeamConstraint[];
    network_constraints: NetworkConstraint[];
  };
};

export const CORE_CONSTRAINT_CATEGORY = [
  'city',
  'competition',
  'exposure',
  'network',
  'rest',
] as const;
export type CoreConstraintCategory = (typeof CORE_CONSTRAINT_CATEGORY)[number];

export const CONSTRAINT_PENALTY = ['none', 'low', 'med', 'high'] as const;
export type ConstraintPenalty = (typeof CONSTRAINT_PENALTY)[number];

export type Matchup = {
  away: TeamType;
  home: TeamType;
};

export type CoreConstraintEntry = {
  name: string;
  description: string;
  category: CoreConstraintCategory;
  hard: boolean;
  max: number;
  penalty: ConstraintPenalty;
  team_exceptions: TeamType[];
  matchup_restrictions: Matchup[];
};

export type StadiumBlockConstraint = {
  team: TeamType;
  week: WeekType;
  slot: ConstraintSlot[];
};

export type TeamConstraintLocation = 'home' | 'away' | 'any' | 'none';

export type TeamConstraint = {
  name: string;
  teams: TeamType[];
  location: TeamConstraintLocation;
  week: WeekExtendedType[];
  networks: NetworkTypeSimpleExtended[];
  min: number;
  max: number;
  holiday_slot?: HolidaySlot;
  hard: boolean;
  penalty: ConstraintPenalty;
};

export type MatchupConstraint = {
  name: string;
  matchup: Matchup;
  week: WeekExtendedType[];
  network: NetworkTypeSimple[];
  inclusive: boolean;
  holiday_slot?: HolidaySlot;
  hard: boolean;
  penalty: ConstraintPenalty;
};

export type NetworkConstraint = {
  name: string;
  network: NetworkTypeSimpleExtended[];
  team: (TeamType | 'All')[];
  week: WeekExtendedType[];
  // TODO(maciek): Figure out how to handle time_window
  time_window: string;
  min: number;
  max: number;
  double_header: boolean;
  hard: boolean;
  penalty: ConstraintPenalty;
};
