import { Flex } from '@mantine/core';
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'clsx';

import { RestSummaryEntry, TEAM_KEYS, TeamType } from 'utils/scheduleConsts';
import { useAppStore } from 'stores/appStore';

import sharedClasses from '../ScheduleEyeChart/EyeChartSharedStyles.module.css';
import classes from './RestSummaryChart.module.css';

const columnHelper = createColumnHelper<RestSummaryEntry>();

const cellGenerator = (info: CellContext<RestSummaryEntry, number | undefined>) => (
  <p
    className={cx([
      classes[Number(info.getValue()) < -4 ? 'negative' : ''],
      classes[Number(info.getValue()) <= 0 ? 'ignored' : ''],
    ])}
  >
    {info.getValue()}
  </p>
);

const columnGenerator = (teams: TeamType[]) =>
  teams.map((team) =>
    columnHelper.accessor(team, {
      cell: cellGenerator,
    })
  );

const columns = [
  columnHelper.display({
    id: 'week',
    cell: (info) => (info.row.original.week === 'total' ? 'Total' : info.row.original.week),
  }),
  columnHelper.group({
    header: 'NFC',
    columns: [
      columnHelper.group({
        header: 'EAST',
        columns: columnGenerator(TEAM_KEYS.slice(0, 4)),
      }),
      columnHelper.group({
        header: 'NORTH',
        columns: columnGenerator(TEAM_KEYS.slice(4, 8)),
      }),
      columnHelper.group({
        header: 'SOUTH',
        columns: columnGenerator(TEAM_KEYS.slice(8, 12)),
      }),
      columnHelper.group({
        header: 'WEST',
        columns: columnGenerator(TEAM_KEYS.slice(12, 16)),
      }),
    ],
  }),
  columnHelper.group({
    header: 'AFC',
    columns: [
      columnHelper.group({
        header: 'EAST',
        columns: columnGenerator(TEAM_KEYS.slice(16, 20)),
      }),
      columnHelper.group({
        header: 'NORTH',
        columns: columnGenerator(TEAM_KEYS.slice(20, 24)),
      }),
      columnHelper.group({
        header: 'SOUTH',
        columns: columnGenerator(TEAM_KEYS.slice(24, 28)),
      }),
      columnHelper.group({
        header: 'WEST',
        columns: columnGenerator(TEAM_KEYS.slice(28, 32)),
      }),
    ],
  }),
];

export function RestSummaryChart() {
  const currentRestSummary = useAppStore((state) => state.currentRestSummary);

  const table = useReactTable({
    data: currentRestSummary ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Flex
      align="center"
      direction="column"
      gap="sm"
      className={cx([sharedClasses.ubiquiaEyeChartContainer, classes.restSummaryChartContainer])}
    >
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
}
