import { Box, Container, Divider, Flex, MultiSelect, Select, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  ByeGuarantee,
  ConstraintEntry,
  ConstraintSlot,
  ConstraintSlotTranslations,
  ConstraintType,
  ConstraintTypeTranslations,
  HolidaySlot,
  HolidaySlotTranslations,
  HomeAwayGuarantee,
  MatchupGuarantee,
  NETWORK_KEYS_PRIMETIME,
  NETWORK_KEYS_SIMPLE,
  NETWORK_KEYS_SIMPLE_EXTENDED,
  NetworkTypeSimple,
  NetworkTypeSimpleExtended,
  PrimetimeMinMax,
  RecursivePartial,
  StadiumBlock,
  TEAM_KEYS,
  TEAM_MATCHUPS,
  TeamGuarantee,
  TeamType,
  WEEK_KEYS,
  WEEK_KEYS_BYE,
  WEEK_KEYS_EXTENDED,
} from 'utils/scheduleConsts';
import { slotSorter } from 'utils/scheduleUtils';

const StadiumBlockEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<StadiumBlock>;
  onChange: (value: RecursivePartial<StadiumBlock>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.slot]);
  return (
    <Flex gap="xl">
      <Select
        w="80"
        withCheckIcon={false}
        allowDeselect={false}
        value={value.params?.team}
        onChange={(team) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              team: team as (typeof TEAM_KEYS)[number],
            },
          })
        }
        data={TEAM_KEYS.slice().sort()}
        searchable
        description="Stadium"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="80"
        withCheckIcon={false}
        allowDeselect={false}
        data={WEEK_KEYS.map((week) => week.toString())}
        disabled={isWeekFieldDisabled}
        value={String(value.params?.week)}
        onChange={(week) => {
          onChange({
            ...value,
            params: {
              ...value.params,
              week: Number(week) as (typeof WEEK_KEYS)[number],
            },
          });
        }}
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="240"
        withCheckIcon={false}
        data={Object.entries(ConstraintSlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        searchable
        // @ts-ignore
        value={value.params?.slot?.length ? value.params?.slot.sort(slotSorter) : []}
        onChange={(slot) => {
          let currSlot = slot;
          let currWeek = value.params?.week ? Number(value.params.week) : undefined;
          const containsAll = slot.includes(ConstraintSlot.ALL);
          const containsTgiv = slot.includes(ConstraintSlot.TGIV);
          const includesChristmas = slot.includes(ConstraintSlot.CHRISTMAS);
          const includesBlackFriday = slot.includes(ConstraintSlot.BLACK_FRIDAY);
          if (containsAll) {
            currSlot = [ConstraintSlot.ALL];
          } else if (containsTgiv) {
            currSlot = [ConstraintSlot.TGIV];
            currWeek = 13;
          } else if (includesChristmas) {
            currSlot = [ConstraintSlot.CHRISTMAS];
            currWeek = 17;
          } else if (includesBlackFriday) {
            currSlot = [ConstraintSlot.BLACK_FRIDAY];
            currWeek = 13;
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              // @ts-ignore
              slot: currSlot,
              // @ts-ignore
              week: currWeek,
            },
          });
        }}
        description="Slot"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const MatchupGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<MatchupGuarantee>;
  onChange: (value: RecursivePartial<MatchupGuarantee>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.holiday_slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.holiday_slot]);
  const [enabledNetworks, setEnabledNetworks] = useState<NetworkTypeSimple[]>(
    NETWORK_KEYS_SIMPLE.slice()
  );
  return (
    <Flex gap="xl">
      <Select
        w="120"
        withCheckIcon={false}
        allowDeselect={false}
        data={TEAM_MATCHUPS}
        value={value.params?.matchup || ''}
        onChange={(matchup) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              matchup: matchup as (typeof TEAM_MATCHUPS)[number],
            },
          })
        }
        searchable
        description="Matchup"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        disabled={isWeekFieldDisabled}
        hidePickedOptions
        w="200"
        withCheckIcon={false}
        data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
        // @ts-ignore
        value={
          value.params?.week
            ? value.params?.week
                ?.sort((a, b) => Number(a) - Number(b))
                .map((week) => week!.toString())
            : []
        }
        onChange={(weekVal) => {
          onChange({
            ...value,
            params: {
              ...value.params,
              week: weekVal.includes('All')
                ? ['All']
                : weekVal
                    .map((week) => Number(week) as (typeof WEEK_KEYS)[number])
                    .sort((a, b) => a - b),
            },
          });
        }}
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="160"
        withCheckIcon={false}
        data={enabledNetworks
          .slice()
          .sort()
          .map((network) => ({
            label: network,
            value: network,
          }))}
        // @ts-ignore
        value={value.params?.network?.sort() || []}
        onChange={(network) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              network: network.sort() as (typeof NETWORK_KEYS_SIMPLE)[number][],
            },
          })
        }
        searchable
        description="Network"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="120"
        withCheckIcon={false}
        allowDeselect={false}
        data={[
          { label: 'True', value: 'true' },
          { label: 'False', value: 'false' },
        ]}
        value={value.params?.inclusive?.toString()}
        onChange={(inclusive) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              inclusive: inclusive === 'true',
            },
          })
        }
        searchable
        description="Inclusive"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="180"
        withCheckIcon={false}
        data={Object.entries(HolidaySlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        value={value.params?.holiday_slot || ''}
        onChange={(slot) => {
          const isTgiv = slot === HolidaySlot.TGIV;
          const isChristmas = slot === HolidaySlot.CHRISTMAS;
          const isBlackFriday = slot === HolidaySlot.BLACK_FRIDAY;

          let currWeek = value.params?.week?.length ? value.params.week : [];
          let currNetwork = value.params?.network?.length ? value.params.network : [];
          if (isBlackFriday) {
            currWeek = [13];
            currNetwork = ['Amz'];
            setEnabledNetworks(['Amz']);
          } else if (isTgiv) {
            currWeek = [13];
            const tgivNetworks = ['CBS', 'Fox', 'NBC'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              tgivNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(tgivNetworks);
          } else if (isChristmas) {
            currWeek = [17];
            const christmasNetworks = ['CBS', 'Fox'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              christmasNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(christmasNetworks);
          } else {
            setEnabledNetworks(NETWORK_KEYS_SIMPLE.slice());
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              week: currWeek,
              network: currNetwork,
              holiday_slot: slot ? (slot as HolidaySlot) : undefined,
            },
          });
        }}
        searchable
        description="Holiday (Optional)"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const TeamGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<TeamGuarantee>;
  onChange: (value: RecursivePartial<TeamGuarantee>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.holiday_slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.holiday_slot]);
  const [enabledNetworks, setEnabledNetworks] = useState<NetworkTypeSimple[]>(
    NETWORK_KEYS_SIMPLE.slice()
  );
  return (
    <Flex gap="xl">
      <MultiSelect
        hidePickedOptions
        w="160"
        withCheckIcon={false}
        data={TEAM_KEYS.slice().sort()}
        // @ts-ignore
        value={value.params?.team?.length ? value.params.team.sort() : []}
        onChange={(team) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              team: team.sort() as (typeof TEAM_KEYS)[number][],
            },
          })
        }
        searchable
        description="Teams"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        disabled={isWeekFieldDisabled}
        hidePickedOptions
        w="120"
        withCheckIcon={false}
        data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
        value={
          // @ts-ignore
          value.params?.week?.length
            ? value.params.week
                .sort((a, b) => Number(a) - Number(b))
                .map((week) => week!.toString())
                .sort()
            : []
        }
        onChange={(week) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              // @ts-ignore
              week: week.includes('All')
                ? ['All']
                : week.sort((a, b) => Number(a) - Number(b)).map((w) => Number(w)),
            },
          })
        }
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="160"
        withCheckIcon={false}
        data={enabledNetworks
          .slice()
          .sort()
          .map((network) => ({
            label: network,
            value: network,
          }))}
        // @ts-ignore
        value={value.params?.network?.length ? value.params.network.sort() : []}
        onChange={(network) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              network: network.sort() as (typeof NETWORK_KEYS_SIMPLE)[number][],
            },
          })
        }
        searchable
        description="Network"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.min_appearances?.toString()}
        onChange={(min_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              min_appearances: Number(min_appearances),
            },
          })
        }
        searchable
        description="Min"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.max_appearances?.toString()}
        onChange={(max_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              max_appearances: Number(max_appearances),
            },
          })
        }
        searchable
        description="Max"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="180"
        withCheckIcon={false}
        data={Object.entries(HolidaySlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        value={value.params?.holiday_slot || ''}
        onChange={(slot) => {
          const isTgiv = slot === HolidaySlot.TGIV;
          const isChristmas = slot === HolidaySlot.CHRISTMAS;
          const isBlackFriday = slot === HolidaySlot.BLACK_FRIDAY;

          let currWeek = value.params?.week?.length ? value.params.week : [];
          let currNetwork = value.params?.network?.length ? value.params.network : [];
          if (isBlackFriday) {
            currWeek = [13];
            currNetwork = ['Amz'];
            setEnabledNetworks(['Amz']);
          } else if (isTgiv) {
            currWeek = [13];
            const tgivNetworks = ['CBS', 'Fox', 'NBC'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              tgivNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(tgivNetworks);
          } else if (isChristmas) {
            currWeek = [17];
            const christmasNetworks = ['CBS', 'Fox'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              christmasNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(christmasNetworks);
          } else {
            setEnabledNetworks(NETWORK_KEYS_SIMPLE.slice());
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              week: currWeek,
              network: currNetwork,
              holiday_slot: slot ? (slot as HolidaySlot) : undefined,
            },
          });
        }}
        searchable
        description="Holiday (Optional)"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const ByeGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<ByeGuarantee>;
  onChange: (value: RecursivePartial<ByeGuarantee>) => void;
}) => (
  <Flex gap="xl">
    <Select
      w="80"
      withCheckIcon={false}
      allowDeselect={false}
      value={value.params?.team}
      onChange={(team) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            team: team as TeamType,
          },
        })
      }
      data={TEAM_KEYS.slice().sort() as TeamType[]}
      searchable
      description="Team"
      inputWrapperOrder={['input', 'description']}
    />
    <MultiSelect
      hidePickedOptions
      w="200"
      withCheckIcon={false}
      data={WEEK_KEYS_BYE.map((week) => week.toString())}
      // @ts-ignore
      value={
        value.params?.week
          ? value.params?.week
              ?.sort((a, b) => Number(a) - Number(b))
              .map((week) => week!.toString())
          : []
      }
      onChange={(weekVal) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            week: weekVal.length
              ? weekVal
                  .map((week) => Number(week) as (typeof WEEK_KEYS_BYE)[number])
                  .sort((a, b) => a - b)
              : [],
          },
        })
      }
      searchable
      description="Week"
      inputWrapperOrder={['input', 'description']}
    />
    <Select
      w="120"
      withCheckIcon={false}
      allowDeselect={false}
      data={[
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ]}
      value={value.params?.inclusive?.toString()}
      onChange={(inclusive) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            inclusive: inclusive === 'true',
          },
        })
      }
      searchable
      description="Inclusive"
      inputWrapperOrder={['input', 'description']}
    />
  </Flex>
);

const HomeAwayGuaranteeEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<HomeAwayGuarantee>;
  onChange: (value: RecursivePartial<HomeAwayGuarantee>) => void;
}) => {
  const [isWeekFieldDisabled, setIsWeekFieldDisabled] = useState(false);
  useEffect(() => {
    setIsWeekFieldDisabled(
      value.params?.holiday_slot?.includes(ConstraintSlot.TGIV) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.CHRISTMAS) ||
        value.params?.holiday_slot?.includes(ConstraintSlot.BLACK_FRIDAY) ||
        false
    );
  }, [value.params?.holiday_slot]);
  const [enabledNetworks, setEnabledNetworks] = useState<NetworkTypeSimpleExtended[]>(
    NETWORK_KEYS_SIMPLE_EXTENDED.slice()
  );
  return (
    <Flex gap="md">
      <MultiSelect
        hidePickedOptions
        w="120"
        withCheckIcon={false}
        data={TEAM_KEYS.slice().sort()}
        // @ts-ignore
        value={value.params?.team?.length ? value.params.team.sort() : []}
        onChange={(team) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              team: team.sort() as (typeof TEAM_KEYS)[number][],
            },
          })
        }
        searchable
        description="Teams"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="160"
        withCheckIcon={false}
        allowDeselect={false}
        data={[
          { label: 'Home', value: 'home' },
          { label: 'Away', value: 'away' },
        ]}
        value={value.params?.home_away?.toString()}
        onChange={(home_away) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              home_away: home_away as 'home' | 'away',
            },
          })
        }
        searchable
        description="Home/Away"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        disabled={isWeekFieldDisabled}
        hidePickedOptions
        w="100"
        withCheckIcon={false}
        data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
        value={
          // @ts-ignore
          value.params?.week?.length
            ? value.params.week
                .sort((a, b) => Number(a) - Number(b))
                .map((week) => week!.toString())
                .sort()
            : []
        }
        onChange={(week) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              // @ts-ignore
              week: week.includes('All')
                ? ['All']
                : week.sort((a, b) => Number(a) - Number(b)).map((w) => Number(w)),
            },
          })
        }
        searchable
        description="Week"
        inputWrapperOrder={['input', 'description']}
      />
      <MultiSelect
        hidePickedOptions
        w="140"
        withCheckIcon={false}
        data={enabledNetworks
          .slice()
          .sort()
          .map((network) => ({
            label: network,
            value: network,
          }))}
        // @ts-ignore
        value={value.params?.network?.length ? value.params.network.sort() : []}
        onChange={(network) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              network: network.includes('All')
                ? ['All']
                : (network.sort() as (typeof NETWORK_KEYS_SIMPLE_EXTENDED)[number][]),
            },
          })
        }
        searchable
        description="Network"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="180"
        withCheckIcon={false}
        data={Object.entries(HolidaySlotTranslations).map(([key, label]) => ({
          label,
          value: key,
        }))}
        value={value.params?.holiday_slot || ''}
        onChange={(slot) => {
          const isTgiv = slot === HolidaySlot.TGIV;
          const isChristmas = slot === HolidaySlot.CHRISTMAS;
          const isBlackFriday = slot === HolidaySlot.BLACK_FRIDAY;

          let currWeek = value.params?.week?.length ? value.params.week : [];
          let currNetwork = value.params?.network?.length ? value.params.network : [];
          if (isBlackFriday) {
            currWeek = [13];
            currNetwork = ['Amz'];
            setEnabledNetworks(['Amz']);
          } else if (isTgiv) {
            currWeek = [13];
            const tgivNetworks = ['CBS', 'Fox', 'NBC'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              tgivNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(tgivNetworks);
          } else if (isChristmas) {
            currWeek = [17];
            const christmasNetworks = ['CBS', 'Fox'] as NetworkTypeSimple[];
            currNetwork = currNetwork.filter((network) =>
              christmasNetworks.includes(network as NetworkTypeSimple)
            );
            setEnabledNetworks(christmasNetworks);
          } else {
            setEnabledNetworks(NETWORK_KEYS_SIMPLE_EXTENDED.slice());
          }
          onChange({
            ...value,
            params: {
              ...value.params,
              week: currWeek,
              network: currNetwork,
              holiday_slot: slot ? (slot as HolidaySlot) : undefined,
            },
          });
        }}
        searchable
        description="Holiday (Optional)"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.min_appearances?.toString()}
        onChange={(min_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              min_appearances: Number(min_appearances),
            },
          })
        }
        searchable
        description="Min"
        inputWrapperOrder={['input', 'description']}
      />
      <Select
        w="100"
        withCheckIcon={false}
        allowDeselect={false}
        data={[0, ...WEEK_KEYS].map((week) => week.toString())}
        value={value.params?.max_appearances?.toString()}
        onChange={(max_appearances) =>
          onChange({
            ...value,
            params: {
              ...value.params,
              max_appearances: Number(max_appearances),
            },
          })
        }
        searchable
        description="Max"
        inputWrapperOrder={['input', 'description']}
      />
    </Flex>
  );
};

const PrimetimeMinMaxEntry = ({
  value,
  onChange,
}: {
  value: RecursivePartial<PrimetimeMinMax>;
  onChange: (value: RecursivePartial<PrimetimeMinMax>) => void;
}) => (
  <Flex gap="xl">
    <MultiSelect
      hidePickedOptions
      w="120"
      withCheckIcon={false}
      data={TEAM_KEYS.slice().sort()}
      // @ts-ignore
      value={value.params?.team?.length ? value.params.team.sort() : []}
      onChange={(team) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            team: team.sort() as (typeof TEAM_KEYS)[number][],
          },
        })
      }
      searchable
      description="Teams"
      inputWrapperOrder={['input', 'description']}
    />
    <MultiSelect
      hidePickedOptions
      w="140"
      withCheckIcon={false}
      data={NETWORK_KEYS_PRIMETIME}
      // @ts-ignore
      value={value.params?.network?.length ? value.params.network.sort() : []}
      onChange={(network) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            network: network.includes('All')
              ? ['All']
              : (network.sort() as (typeof NETWORK_KEYS_PRIMETIME)[number][]),
          },
        })
      }
      searchable
      description="Network"
      inputWrapperOrder={['input', 'description']}
    />
    <Select
      w="100"
      withCheckIcon={false}
      allowDeselect={false}
      data={[0, ...WEEK_KEYS].map((week) => week.toString())}
      value={value.params?.min_appearances?.toString()}
      onChange={(min_appearances) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            min_appearances: Number(min_appearances),
          },
        })
      }
      searchable
      description="Min"
      inputWrapperOrder={['input', 'description']}
    />
    <Select
      w="100"
      withCheckIcon={false}
      allowDeselect={false}
      data={[0, ...WEEK_KEYS].map((week) => week.toString())}
      value={value.params?.max_appearances?.toString()}
      onChange={(max_appearances) =>
        onChange({
          ...value,
          params: {
            ...value.params,
            max_appearances: Number(max_appearances),
          },
        })
      }
      searchable
      description="Max"
      inputWrapperOrder={['input', 'description']}
    />
  </Flex>
);

export function ConstraintEditor({
  value,
  onChange,
}: {
  value: RecursivePartial<ConstraintEntry>;
  onChange: (value: RecursivePartial<ConstraintEntry>) => void;
}) {
  return (
    <Box>
      <Container fluid>
        <Stack>
          <Select
            w={240}
            label="Constraint type"
            data={Object.values(ConstraintType).map((type) => ({
              label: ConstraintTypeTranslations[type],
              value: type,
            }))}
            value={value.type}
            onChange={(_value, option) => onChange({ type: option.value as ConstraintType })}
          />
          <Divider />
          {value.type === ConstraintType.STADIUM_BLOCK && (
            <StadiumBlockEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.MATCHUP_GUARANTEE && (
            <MatchupGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.TEAM_GUARANTEE && (
            <TeamGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.BYE_GUARANTEE && (
            <ByeGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.HOME_AWAY_GUARANTEE && (
            <HomeAwayGuaranteeEntry value={value} onChange={onChange} />
          )}
          {value.type === ConstraintType.PRIMETIME_MIN_MAX && (
            <PrimetimeMinMaxEntry value={value} onChange={onChange} />
          )}
        </Stack>
      </Container>
    </Box>
  );
}
