'use client';

import { useForm } from '@mantine/form';

import { TextInput, Flex, Container, Image, Button, Paper, rem } from '@mantine/core';
import axios from 'axios';
import { getApiUrl } from 'api/api';
import { useState } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import ubiquiaLogo from 'assets/ubiquia_logo.svg';

const forgotPassword = async ({ email }: { email: string }): Promise<any> => {
  const postReq = await axios.post(`${getApiUrl()}/api/v1/password/forgot/`, {
    email,
  });

  return postReq.data;
};

export default function ForgotPassword() {
  const [didSubmit, setDidSubmit] = useState(false);
  const handleSubmit = async (values: { email: string }) => {
    try {
      await forgotPassword({ email: values.email });
    } catch {
      /* empty */
    } finally {
      setDidSubmit(true);
    }
  };

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  return (
    <Container size={420} my={40} className="h-full">
      <Flex justify="center">
        <Image src={ubiquiaLogo} alt="Ubiquia" h={rem(60)} w="auto" fit="contain" />
      </Flex>
      <Paper withBorder shadow="md" p={30} mt={15} radius="md">
        {didSubmit ? (
          <div className="flex text-center">Please check your email for a reset password link.</div>
        ) : (
          <form onSubmit={form.onSubmit((values: any) => handleSubmit(values))}>
            <TextInput
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps('email')}
            />
            <Button type="submit" fullWidth mt="xl">
              Submit
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
}

export const Route = createFileRoute('/forgotPassword')({
  component: ForgotPassword,
});
