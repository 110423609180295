/* eslint-disable no-restricted-syntax */
import { Box, Container, Flex, Select, TextInput, Stack, MultiSelect } from '@mantine/core';
import { CONSTRAINT_PENALTY, ConstraintPenalty, MatchupConstraint } from 'utils/constraintConsts';
import {
  NETWORK_KEYS_SIMPLE,
  NetworkTypeSimple,
  RecursivePartial,
  TEAM_KEYS,
  TeamType,
  WEEK_KEYS_EXTENDED,
  WeekExtendedType,
} from 'utils/scheduleConsts';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const ALL_MATCHUPS = TEAM_KEYS.flatMap((homeTeam) =>
  TEAM_KEYS.filter((awayTeam) => awayTeam !== homeTeam)
    .map((awayTeam) => `${awayTeam}-${homeTeam}`)
    .sort()
);

const MatchupEntry = ({
  constraint,
  onChange,
}: {
  constraint: RecursivePartial<MatchupConstraint>;
  onChange: (value: RecursivePartial<MatchupConstraint>) => void;
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <Stack>
      <Flex gap="xl">
        <TextInput
          w="240"
          description="Name"
          value={constraint.name}
          onChange={(event) => onChange({ ...constraint, name: event.currentTarget.value })}
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="140"
          searchable
          allowDeselect={false}
          value={constraint.matchup ? `${constraint.matchup.away}-${constraint.matchup.home}` : ''}
          onChange={(matchup) => {
            const [away, home] = matchup!.split('-');
            onChange({
              ...constraint,
              matchup: {
                away: away as TeamType,
                home: home as TeamType,
              },
            });
          }}
          data={ALL_MATCHUPS}
          description="Matchup"
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="140"
          searchable
          // @ts-ignore
          value={constraint.week?.map((week) => week.toString())}
          onChange={(week) => {
            const newVals: WeekExtendedType[] = [];
            for (const val of week) {
              const newVal = val === 'All' ? 'All' : Number(val);
              newVals.push(newVal as WeekExtendedType);
            }
            onChange({
              ...constraint,
              week: newVals,
            });
          }}
          data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
          description="Week"
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="140"
          searchable
          // @ts-ignore
          value={constraint.network?.map((network) => network.toString())}
          onChange={(network) => {
            onChange({
              ...constraint,
              network: network as NetworkTypeSimple[],
            });
          }}
          data={NETWORK_KEYS_SIMPLE}
          description="Network"
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="120"
          withCheckIcon={false}
          description="Inclusive"
          inputWrapperOrder={['input', 'description']}
          allowDeselect={false}
          data={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={constraint.inclusive ? 'true' : 'false'}
          onChange={(value) => onChange({ ...constraint, inclusive: value === 'true' })}
        />
        <Select
          w="120"
          withCheckIcon={false}
          description="Hard"
          inputWrapperOrder={['input', 'description']}
          allowDeselect={false}
          data={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={constraint.hard ? 'true' : 'false'}
          onChange={(value) => onChange({ ...constraint, hard: value === 'true' })}
        />
        <Select
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          value={constraint.penalty}
          onChange={(penalty) =>
            onChange({
              ...constraint,
              penalty: penalty as ConstraintPenalty,
            })
          }
          data={CONSTRAINT_PENALTY.map((entry) => ({
            label: entry.charAt(0).toUpperCase() + entry.slice(1),
            value: entry,
          }))}
          description="Penalty"
          inputWrapperOrder={['input', 'description']}
        />
      </Flex>
    </Stack>
  );
};

export function MatchupConstraintEditor({
  value,
  onChange,
}: {
  value: RecursivePartial<MatchupConstraint>;
  onChange: (value: RecursivePartial<MatchupConstraint>) => void;
}) {
  return (
    <Box>
      <Container size="xl">
        <MatchupEntry constraint={value} onChange={onChange} />
      </Container>
    </Box>
  );
}
