import { Box, Flex } from '@mantine/core';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { MARKET_SUMMARY_TEAM_COLORS, MarketType, MarketsSummaryEntry } from 'utils/scheduleConsts';
import { useAppStore } from 'stores/appStore';

import classes from './MarketsSummary.module.css';

const columnHelper = createColumnHelper<MarketsSummaryEntry>();

export function MarketsSummary() {
  const currentMarketsSummary = useAppStore((state) => state.currentMarketsSummary);

  const columns = (market: MarketType) => [
    columnHelper.accessor('week', {
      cell: (info) => info.getValue(),
      header: undefined,
    }),
    columnHelper.accessor('team_1', {
      header: () => {
        const team_1 = currentMarketsSummary?.[market]?.team_1;
        return (
          // @ts-ignore
          <div style={{ backgroundColor: MARKET_SUMMARY_TEAM_COLORS[team_1] || 'black' }}>
            {team_1 || ''}
          </div>
        );
      },
      cell: (info) => (
        <Box
          component="span"
          style={() => ({
            color: 'black',
            fontWeight: 'normal',
            textAlign: 'center',
          })}
        >
          {`${info.getValue()}`}
        </Box>
      ),
    }),
    columnHelper.accessor('team_2', {
      header: () => {
        const team_2 = currentMarketsSummary?.[market]?.team_2;
        return (
          // @ts-ignore
          <div style={{ backgroundColor: MARKET_SUMMARY_TEAM_COLORS[team_2] || 'black' }}>
            {team_2 || ''}
          </div>
        );
      },
      cell: (info) => (
        <Box
          component="span"
          style={() => ({
            color: 'black',
            fontWeight: 'normal',
            textAlign: 'center',
          })}
        >
          {`${info.getValue()}`}
        </Box>
      ),
    }),
  ];

  const table1 = useReactTable({
    data: currentMarketsSummary ? currentMarketsSummary['New York'].market_data : [],
    columns: columns('New York'),
    getCoreRowModel: getCoreRowModel(),
  });

  const table2 = useReactTable({
    data: currentMarketsSummary ? currentMarketsSummary['Los Angeles'].market_data : [],
    columns: columns('Los Angeles'),
    getCoreRowModel: getCoreRowModel(),
  });

  const table3 = useReactTable({
    data: currentMarketsSummary ? currentMarketsSummary['Baltimore / DC'].market_data : [],
    columns: columns('Baltimore / DC'),
    getCoreRowModel: getCoreRowModel(),
  });

  return currentMarketsSummary ? (
    <Flex align="left" direction="row" gap="sm" className={classes.marketsSummaryContainer}>
      <table style={{ fontSize: 15 }}>
        <thead>
          {table1.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table1.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{ fontSize: 15 }}>
        <thead>
          {table2.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table2.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{ fontSize: 15 }}>
        <thead>
          {table3.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table3.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  ) : null;
}
