import {
  BuildContext,
  BuildListEntry,
  FullBuildEntry,
  FullScheduleEntry,
  PostConstraintsResponse,
  UploadsPostResponse,
  SummaryHighlightsInfo,
  UploadsListEntry,
  UserInfoResponse,
} from 'utils/scheduleConsts';
import ubiquiaAxios from 'api/ubiquiaAxios';

import JSZip from 'jszip';

// Note(maciek): Unused endpoint.
// export const getSchedulesListRemote = async (): Promise<ScheduleListEntry[]> => {
//   const { data }: { data: ScheduleListEntry[] } = await ubiquiaAxios.get(
//     '/api/v1/schedule/results/'
//   );
//   return data;
// };

export const getBuildsListRemote = async (): Promise<BuildListEntry[]> => {
  const { data }: { data: BuildListEntry[] } = await ubiquiaAxios.get('/api/v1/schedule/builds/');
  return data;
};

export const getUploadsListRemote = async (): Promise<UploadsListEntry[]> => {
  const { data }: { data: UploadsListEntry[] } = await ubiquiaAxios.get(
    '/api/v1/schedule/uploads/'
  );
  return data;
};

export const getBuildContextRemote = async (id: string): Promise<BuildContext> => {
  const { data }: { data: FullBuildEntry } = await ubiquiaAxios.get(
    `/api/v1/schedule/builds/${id}/`
  );
  return data.context;
};

export const getAllScheduleDataRemote = async (id: string): Promise<FullScheduleEntry> => {
  const { data }: { data: FullScheduleEntry } = await ubiquiaAxios.get(
    `/api/v1/schedule/results/${id}/`
  );
  return data;
};

export const getHighlightsFromScheduleRemote = async (
  id: string
): Promise<SummaryHighlightsInfo> => {
  const { data }: { data: FullScheduleEntry } = await ubiquiaAxios.get(
    `/api/v1/schedule/results/${id}/`
  );
  return data.results.summary_highlights;
};

export const postConstraintsRemote = async (
  context: BuildContext,
  buildName: string
): Promise<PostConstraintsResponse> => {
  const postReq = await ubiquiaAxios.post('/api/v1/schedule/builds/', {
    user: window.localStorage.getItem('nfl-ubiquia-user-id') || '',
    name: buildName,
    context,
  });
  return postReq.data;
};

export const postManualScheduleBundleRemote = async (
  name: string,
  files: File[]
): Promise<UploadsPostResponse> => {
  const zip = new JSZip();
  const resultsNames = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    // eslint-disable-next-line no-await-in-loop
    const fileData = await file.arrayBuffer();
    zip.file(file.name, fileData);

    // Remove `.xslx` and replace `:` with `/` to handle macOS encoding.
    resultsNames.push(file.name.substring(0, file.name.length - 5).replaceAll(':', '/'));
  }

  const formData = new FormData();
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  formData.append('file', zipBlob, 'schedule_bundle.zip');
  formData.append('results_names', JSON.stringify(resultsNames));
  formData.append('name', name);
  formData.append('user', window.localStorage.getItem('nfl-ubiquia-user-id') || '');

  const postReq = await ubiquiaAxios.post('/api/v1/schedule/uploads/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return postReq.data;
};

export const renameScheduleRemote = async (
  id: string,
  name: string
): Promise<FullScheduleEntry> => {
  const putReq = await ubiquiaAxios.put(`/api/v1/schedule/results/${id}/`, {
    name,
  });
  return putReq.data;
};

export const getUserInfoRemote = async (): Promise<UserInfoResponse> => {
  const getReq = await ubiquiaAxios.get('/api/v1/users/info/');
  return getReq.data;
};
