import { createFileRoute, useNavigate } from '@tanstack/react-router';

import {
  Container,
  Stack,
  Title,
  Divider,
  LoadingOverlay,
  Text,
  Group,
  Anchor,
  Box,
  TextInput,
  Button,
  FileInput,
  FileInputProps,
  Pill,
  List,
} from '@mantine/core';
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useAppStore } from 'stores/appStore';
import { BuildListEntry } from 'utils/scheduleConsts';
import { modals } from '@mantine/modals';
import { postManualScheduleBundle } from 'api/api';

const ValueComponent: FileInputProps['valueComponent'] = ({ value }) => {
  if (value === null) {
    return null;
  }

  if (Array.isArray(value)) {
    return (
      <Stack gap="xs">
        {value.map((file, index) => (
          // Using withRemoveButton here causes a dom validation warning validateDOMNesting.
          <Box key={`${index}-${file.name}`}>
            <Pill>{file.name.replaceAll(':', '/')}</Pill>
          </Box>
        ))}
      </Stack>
    );
  }

  return <Pill>{value.name}</Pill>;
};

function JobManager() {
  const [buildName, setBuildName] = useState<string>(useAppStore.getState().getNextBuildName());

  useEffect(() => {
    setBuildName(useAppStore.getState().getNextBuildName());
  }, [useAppStore.getState().buildsList, useAppStore.getState().uploadsList]);

  const [scheduleFiles, setScheduleFiles] = useState<File[]>([]);

  const [isAppLoading, setIsAppLoading] = useAppStore((store) => [
    store.isAppLoading,
    store.setIsAppLoading,
  ]);
  const schedulesList = useAppStore((store) => store.schedulesList);
  const uploadsList = useAppStore((store) => store.uploadsList);

  const setCurrentScheduleId = useAppStore((state) => state.setCurrentScheduleId);
  const navigate = useNavigate({ from: '/jobmanager' });

  const buildsList = useAppStore((store) => store.buildsList);

  const buildTableColumns = useMemo<MRT_ColumnDef<BuildListEntry>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Build Name',
      },
      {
        accessorFn: (entry) => {
          const isDone =
            entry.jobs.length &&
            entry.jobs.every((job) => {
              if (['failed', 'infeasible'].includes(job.status)) {
                return true;
              }
              if (job.status === 'solved') {
                return entry.results.some((result) => result.job_id === job.id);
              }
              return false;
            });
          return isDone ? 'Done' : 'Processing';
        },
        header: 'Status',
      },
      {
        accessorFn: (entry) =>
          new Date(entry.created_at)
            .toLocaleString('en-US', {
              dateStyle: 'short',
              timeStyle: 'short',
            })
            .replace(',', ' -'),
        header: 'Date Submitted',
        sortingFn: 'datetime',
      },
      {
        accessorFn: (entry) => entry.results.length,
        header: 'Schedules Generated',
      },
    ],
    []
  );

  const handleViewScheduleClick = (id: string) => {
    setCurrentScheduleId(id);
    navigate({ to: '/schedules' });
  };

  const jobsTable = useMantineReactTable({
    data: buildsList,
    columns: buildTableColumns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    initialState: { density: 'xs', pagination: { pageSize: 30, pageIndex: 0 }, expanded: true },
    paginationDisplayMode: 'pages',
    renderDetailPanel: ({ row }) => (
      <Stack gap="xs" px="xl">
        {row.original.results.map((schedule) => (
          <Group key={schedule.id} gap="xl">
            <Text title={schedule.name} size="xs" truncate="end">
              {schedule.name}
            </Text>
            <Anchor
              onClick={() => {
                handleViewScheduleClick(schedule.id);
              }}
              size="xs"
            >
              View Schedule
            </Anchor>
          </Group>
        ))}
      </Stack>
    ),
    mantineDetailPanelProps: {
      title: 'Schedules Generated',
      style: { backgroundColor: 'var(--mantine-color-gray-1)' },
    },
  });

  return (
    <Container fluid mt="md" mb="xl">
      <LoadingOverlay
        visible={isAppLoading}
        zIndex={1000}
        overlayProps={{ radius: 'xl', blur: 2 }}
      />
      <Stack>
        <Title order={2}>Job Manager</Title>
        <Divider />
        <Stack gap={6}>
          <Title order={4}>Schedule Upload</Title>
          <Text size="xs">Upload a schedule XLSX file.</Text>
          <Divider />
          <Group align="start">
            <TextInput
              value={buildName}
              onChange={(e) => setBuildName(e.currentTarget.value)}
              style={{ width: '240px' }}
              size="sm"
              label="Build name"
              placeholder="Enter build name"
            />
            <FileInput
              miw={300}
              label="Choose files"
              placeholder="Click here to upload multiple files"
              multiple
              accept=".xlsx"
              clearable
              valueComponent={ValueComponent}
              value={scheduleFiles}
              onChange={(files) => {
                let scheduleNames = schedulesList.map((schedule) => schedule.name);

                // Append upload file names to the schedule names in case results entries
                // have not been created yet.
                const uploadFileNames = uploadsList.map((upload) => upload.results_names).flat();
                scheduleNames = [...scheduleNames, ...uploadFileNames];

                const validFiles = [];
                const invalidFiles = [];
                // eslint-disable-next-line no-restricted-syntax
                for (const file of files) {
                  // Note: `/` is replaced with `:` during upload in macOS.
                  const scheduleName = file.name
                    .substring(0, file.name.length - 5)
                    .replaceAll(':', '/');
                  if (scheduleNames.includes(scheduleName)) {
                    invalidFiles.push(file);
                  } else {
                    validFiles.push(file);
                    scheduleNames.push(scheduleName);
                  }
                }
                setScheduleFiles(validFiles);

                if (invalidFiles.length > 0) {
                  modals.openConfirmModal({
                    title: 'WARNING: Some files were skipped',
                    children: (
                      <Stack>
                        <Text size="sm">
                          The files below were skipped because a schedule with the same name already
                          exists:
                        </Text>
                        <List size="sm">
                          {invalidFiles.map((file) => (
                            <List.Item key={file.name}>{file.name.replaceAll(':', '/')}</List.Item>
                          ))}
                        </List>
                      </Stack>
                    ),
                    labels: { confirm: 'Close', cancel: '' },
                    cancelProps: { display: 'none' },
                    onConfirm: () => {
                      modals.closeAll();
                    },
                  });
                }
              }}
            />
            <Button
              variant="filled"
              size="sm"
              mt="25px"
              disabled={scheduleFiles.length === 0 || buildName === ''}
              onClick={() => {
                const modalTitle = 'Are you sure you want to submit?';
                const modalText = 'Your draft will be cleared after submission.';
                modals.openConfirmModal({
                  title: modalTitle,
                  children: <Text size="sm">{modalText}</Text>,
                  labels: { confirm: 'Submit', cancel: 'Cancel' },
                  onConfirm: async () => {
                    setIsAppLoading(true);
                    try {
                      await postManualScheduleBundle(buildName, scheduleFiles);
                      modals.openConfirmModal({
                        title: 'Submission Received',
                        children: <Text size="sm">Check for an email in your inbox soon.</Text>,
                        labels: { confirm: 'Close', cancel: '' },
                        cancelProps: { display: 'none' },
                        onConfirm: () => {
                          modals.closeAll();
                        },
                        onClose: () => {
                          // Refresh the page.
                          window.history.go();
                        },
                      });
                    } catch (error: any) {
                      // Start with a generic error and title.
                      let errorTitle = 'Error';
                      let errorMsg =
                        'An error has occurred. If this happens again, please contact support.';
                      if (error?.response?.status === 400) {
                        if (
                          error?.response?.data?.name?.[0] ===
                          'Upload with this name already exists.'
                        ) {
                          // A build collision is detected.
                          errorTitle = 'ERROR: Build name exists';
                          errorMsg =
                            'A schedule build with this name already exists. Please choose a different name.';
                        } else if (
                          error?.response?.data?.results_names?.[0] ===
                          'Upload with this results names already exists.'
                        ) {
                          // A file upload collision is detected (exact match - all files).
                          errorTitle = 'ERROR: Duplicate files found';
                          errorMsg =
                            'It appears that these files have already been uploaded. Please rename: ';
                          errorMsg += scheduleFiles
                            .map((file) => file.name.replaceAll(':', '/'))
                            .join(', ');
                        } else if (
                          error?.response?.data?.error?.startsWith('File names ') &&
                          error?.response?.data?.error?.endsWith(' already exist as file names')
                        ) {
                          // A file upload collision is detected (partial match).
                          errorTitle = 'ERROR: Duplicate files found';
                          errorMsg = `${error?.response?.data?.error}. Please rename these files.`;
                        } else if (
                          error?.response?.data?.error?.startsWith('File names ') &&
                          error?.response?.data?.error?.endsWith(' already exist as results')
                        ) {
                          // Colliding with an existing schedule name (this will be triggered first
                          // ahead of the errors above).
                          errorTitle = 'ERROR: Schedule name taken';
                          errorMsg = `${error?.response?.data?.error}. Please rename these files.`;
                        }
                      }

                      // Open an error modal.
                      modals.openConfirmModal({
                        title: errorTitle,
                        children: <Text size="sm">{errorMsg}</Text>,
                        onConfirm: () => {
                          modals.closeAll();
                        },
                        labels: { confirm: 'Continue', cancel: '' },
                        cancelProps: { display: 'none' },
                      });
                    }
                    setIsAppLoading(false);
                  },
                });
              }}
            >
              Submit
            </Button>
          </Group>
        </Stack>
        <Stack gap={6}>
          <Title order={4}>Job Status</Title>
          <Text size="xs">View all schedule job statuses.</Text>
          <Divider />
          <Box mt="sm">
            <MantineReactTable table={jobsTable} />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}

export const Route = createFileRoute('/_authenticated/jobmanager')({
  component: JobManager,
});
