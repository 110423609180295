import {
  BuildContext,
  BuildListEntry,
  FullScheduleEntry,
  PostConstraintsResponse,
  UploadsPostResponse,
  SummaryHighlightsInfo,
  UploadsListEntry,
  UserInfoResponse,
} from 'utils/scheduleConsts';
import {
  getBuildsListRemote,
  getBuildContextRemote,
  getHighlightsFromScheduleRemote,
  postConstraintsRemote,
  renameScheduleRemote,
  getUserInfoRemote,
  postManualScheduleBundleRemote,
  getUploadsListRemote,
} from 'api/remoteApi';
import { getBuildsListLocal, getHighlightsFromScheduleLocal } from 'api/localFoldersApi';

export const getApiUrl = () => import.meta.env.VITE_BACKEND_URL || 'local';

export const isUsingLocalFolders = () => getApiUrl() === 'local';

export const getBuildsList = async (): Promise<BuildListEntry[]> => {
  if (isUsingLocalFolders()) {
    return getBuildsListLocal();
  }
  return getBuildsListRemote();
};

export const getUploadsList = async (): Promise<UploadsListEntry[]> => getUploadsListRemote();

export const getBuildContext = async (id: string): Promise<BuildContext> =>
  getBuildContextRemote(id);

export const getHighlightsFromSchedule = async (id: string): Promise<SummaryHighlightsInfo> => {
  if (isUsingLocalFolders()) {
    return getHighlightsFromScheduleLocal(id);
  }
  return getHighlightsFromScheduleRemote(id);
};

export const postConstraints = async (
  context: BuildContext,
  buildName: string
): Promise<PostConstraintsResponse> => postConstraintsRemote(context, buildName);

export const postManualScheduleBundle = async (
  name: string,
  files: File[]
): Promise<UploadsPostResponse> => postManualScheduleBundleRemote(name, files);

export const downloadXlsx: (id: string, name: string, remoteUrl: string) => Promise<void> = async (
  id: string,
  name: string,
  remoteUrl: string
) => {
  let url = '';
  if (!isUsingLocalFolders()) {
    url = remoteUrl;
  } else {
    const importResult = await import(`../data/schedules/${id}/schedule.xlsx`);
    const response = await fetch(importResult.default);
    const blob = await response.blob();
    url = window.URL.createObjectURL(blob);
  }
  const a = document.createElement('a');
  a.href = url;
  // @ts-ignore
  a.download = `${name}.xlsx`;
  a.click();
};

export const renameSchedule = async (id: string, name: string): Promise<FullScheduleEntry> =>
  renameScheduleRemote(id, name);

export const getUserInfo = async (): Promise<UserInfoResponse> => getUserInfoRemote();
