import { useEffect, useState } from 'react';
import { Box, Flex, Stack, Text, Select, rem, Group } from '@mantine/core';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'clsx';

import { NetworkType, TEAM_KEYS, TeamSummaryEntry, TeamType, WeekType } from 'utils/scheduleConsts';
import { useAppStore } from 'stores/appStore';

import classes from './TeamSummary.module.css';

function get_time_info_value(info: any) {
  if (info.getValue() === 'TBD') {
    return 'TBD';
  }
  if (info.getValue()) {
    return `${info.getValue()} pm`;
  }
  return '';
}

const displayDaysInOrder = ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'];
function get_date_info_value(info: any) {
  if (info.getValue()) {
    // Add 00:00:00 to the end of the day to make sure that the time zone does not
    // cause the previous day to be returned.
    const the_date = new Date(`${info.getValue()} 00:00:00`);
    const dayIdx = the_date.getDay();
    return `${displayDaysInOrder[dayIdx]} ${the_date.toLocaleDateString('en-US')}`;
  }
  return '';
}

const columnHelper = createColumnHelper<TeamSummaryEntry>();

const columns = [
  columnHelper.accessor('week', {
    cell: (info) => info.getValue(),
    header: undefined,
  }),
  columnHelper.accessor('opponent', {
    header: 'Opponent',
    cell: (info) => (
      <Box
        component="span"
        style={() => ({
          color: 'black',
          fontWeight: 'normal',
          textAlign: 'center',
        })}
      >
        {`${info.getValue()}`}
      </Box>
    ),
  }),
  columnHelper.accessor('network', {
    cell: (info) => (
      <p className={cx([classes.networkCell, classes[`${info.getValue().toLowerCase()}`]])}>
        {info.getValue()}
      </p>
    ),
    header: 'Network',
  }),

  columnHelper.accessor('date', {
    cell: (info) => (
      <Text
        component="span"
        style={() => ({
          color: 'black',
          fontWeight: 'normal',
          textAlign: 'right',
        })}
      >
        {`${get_date_info_value(info)}`}
      </Text>
    ),
    header: 'Date',
  }),

  columnHelper.accessor((entry) => entry['time.of.day'], {
    header: 'Time (ET)',
    cell: (info) => (
      <Box
        component="span"
        style={() => ({
          color: 'black',
          fontWeight: 'normal',
          textAlign: 'right',
        })}
      >
        {`${get_time_info_value(info)}`}
      </Box>
    ),
  }),
  columnHelper.accessor(
    (entry) =>
      entry['national.game'] === true ? (
        <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faCircleCheck} />
      ) : (
        ''
      ),
    {
      cell: (info) => info.getValue(),
      header: 'National',
    }
  ),
  columnHelper.accessor((entry) => entry['matchup.rank'], {
    header: 'Rank',
    cell: (info) => (
      <Box
        component="span"
        style={() => ({
          color: 'black',
          fontWeight: 'normal',
          textAlign: 'center',
        })}
      >
        {`${info.getValue()}`}
      </Box>
    ),
  }),
];

export function TeamSummary() {
  const navigate = useNavigate({ from: '/schedules' });
  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedTeam, setSelectedTeam] = useState<TeamType>(
    routeSearch.team ? routeSearch.team : 'ATL'
  );
  const [data, setData] = useState<TeamSummaryEntry[]>([]);
  const currentTeamSummary = useAppStore((state) => state.currentTeamSummary);

  useEffect(() => {
    if (currentTeamSummary !== null) {
      // @ts-ignore
      const newData = currentTeamSummary[selectedTeam];
      const missingWeeks = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= 18; i++) {
        if (!newData.some((entry: any) => entry.week === i)) {
          missingWeeks.push(i);
        }
      }
      // TODO(maciek): One week is missing (bye week) - fix the type deifintions.
      missingWeeks.forEach((week) => {
        // Add a dummy entry for bye weeks.
        newData.push({
          week: week as WeekType,
          opponent: 'Bye Week' as TeamType,
          network: '' as NetworkType,
          date: '',
          'time.of.day': '',
          'national.game': false,
          // @ts-ignore
          'matchup.rank': '',
        });
      });
      newData.sort((a: any, b: any) => a.week - b.week);

      setData(newData);

      if (selectedTeam === 'ATL') {
        navigate({ to: '/schedules', search: (prev) => ({ s: prev.s, t: 'team_summary' }) });
      } else {
        navigate({
          to: '/schedules',
          search: (prev) => ({ s: prev.s, t: 'team_summary', team: selectedTeam }),
        });
      }
    }
  }, [currentTeamSummary, selectedTeam]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Stack gap="xs">
      <Group align="center">
        <Text>Team</Text>
        <Select
          value={selectedTeam}
          onChange={(value) => setSelectedTeam(value as TeamType)}
          allowDeselect={false}
          data={TEAM_KEYS.slice().sort()}
          searchable
          w="100px"
        />
      </Group>
      <Flex align="left" direction="column" gap="sm" className={classes.teamSummaryContainer}>
        <table style={{ fontSize: 15 }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
    </Stack>
  );
}
