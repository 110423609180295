/* eslint-disable no-restricted-syntax */
import {
  Box,
  Container,
  Flex,
  Select,
  TextInput,
  Stack,
  Divider,
  NumberInput,
  MultiSelect,
} from '@mantine/core';
import {
  BroadcastWindowConstraint,
  CONSTRAINT_PENALTY,
  ConstraintPenalty,
  TeamConstraint,
  TeamConstraintLocation,
} from 'utils/constraintConsts';
import {
  HolidaySlot,
  HolidaySlotTranslations,
  NETWORK_KEYS_SIMPLE,
  NetworkTypeSimple,
  RecursivePartial,
  TEAM_KEYS,
  TeamType,
  WEEK_KEYS_EXTENDED,
  WeekExtendedType,
} from 'utils/scheduleConsts';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const BroadcastWindowEntry = ({
  constraint,
  onChange,
}: {
  constraint: RecursivePartial<TeamConstraint>;
  onChange: (value: RecursivePartial<TeamConstraint>) => void;
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <Stack>
      <Flex gap="xl">
        <TextInput
          w="240"
          description="Name"
          value={constraint.name}
          onChange={(event) => onChange({ ...constraint, name: event.currentTarget.value })}
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="120"
          searchable
          // @ts-ignore
          value={constraint.teams}
          onChange={(teams) =>
            onChange({
              ...constraint,
              teams: teams as TeamType[],
            })
          }
          data={TEAM_KEYS}
          description="Teams"
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          value={constraint.location}
          onChange={(location) =>
            onChange({
              ...constraint,
              location: location as TeamConstraintLocation,
            })
          }
          data={['home', 'away', 'any', 'none'].map((location) => ({
            label: location.charAt(0).toUpperCase() + location.slice(1),
            value: location,
          }))}
          description="Location"
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="140"
          searchable
          // @ts-ignore
          value={constraint.week?.map((week) => week.toString())}
          onChange={(week) => {
            const newVals: WeekExtendedType[] = [];
            for (const val of week) {
              const newVal = val === 'All' ? 'All' : Number(val);
              newVals.push(newVal as WeekExtendedType);
            }
            onChange({
              ...constraint,
              week: newVals,
            });
          }}
          data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
          description="Week"
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="140"
          searchable
          // @ts-ignore
          value={constraint.networks?.map((network) => network.toString())}
          onChange={(networks) => {
            onChange({
              ...constraint,
              networks: networks as NetworkTypeSimple[],
            });
          }}
          data={NETWORK_KEYS_SIMPLE}
          description="Network"
          inputWrapperOrder={['input', 'description']}
        />
      </Flex>
      <Divider />
      <Flex gap="xl">
        <NumberInput
          w="80"
          description="Min games"
          value={constraint.min}
          onChange={(e) => {
            const value = Number(e);
            if (value <= (constraint.max ?? 0) && value >= 0) {
              onChange({ ...constraint, min: value });
            }
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <NumberInput
          w="80"
          description="Max games"
          value={constraint.max}
          onChange={(e) => {
            const value = Number(e);
            if (value >= (constraint.min ?? 0) && value >= 0) {
              onChange({ ...constraint, max: value });
            }
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="140"
          clearable
          description="Holiday"
          inputWrapperOrder={['input', 'description']}
          data={Object.entries(HolidaySlotTranslations).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          value={constraint.holiday_slot}
          onChange={(value) =>
            onChange({ ...constraint, holiday_slot: (value as HolidaySlot) || undefined })
          }
        />
        <Select
          w="120"
          withCheckIcon={false}
          description="Hard"
          inputWrapperOrder={['input', 'description']}
          allowDeselect={false}
          data={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={constraint.hard ? 'true' : 'false'}
          onChange={(value) => onChange({ ...constraint, hard: value === 'true' })}
        />
        <Select
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          value={constraint.penalty}
          onChange={(penalty) =>
            onChange({
              ...constraint,
              penalty: penalty as ConstraintPenalty,
            })
          }
          data={CONSTRAINT_PENALTY.map((entry) => ({
            label: entry.charAt(0).toUpperCase() + entry.slice(1),
            value: entry,
          }))}
          description="Penalty"
          inputWrapperOrder={['input', 'description']}
        />
      </Flex>
    </Stack>
  );
};

export function TeamConstraintEditor({
  value,
  onChange,
}: {
  value: RecursivePartial<BroadcastWindowConstraint>;
  onChange: (value: RecursivePartial<BroadcastWindowConstraint>) => void;
}) {
  return (
    <Box>
      <Container size="xl">
        <BroadcastWindowEntry constraint={value} onChange={onChange} />
      </Container>
    </Box>
  );
}
