import { useMemo } from 'react';
import { Flex } from '@mantine/core';
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import cx from 'clsx';

import {
  AFC_TEAM_KEYS,
  EyeChartCellEntry,
  EyeChartIndexRow,
  NFC_TEAM_KEYS,
  TEAM_KEYS,
  TeamType,
} from 'utils/scheduleConsts';
import { useAppStore } from 'stores/appStore';
import { getEyeChartIndex } from 'utils/scheduleUtils';

import sharedClasses from './EyeChartSharedStyles.module.css';
import classes from './ScheduleEyeChart.module.css';

const columnHelper = createColumnHelper<EyeChartIndexRow>();

const cellGenerator =
  (team: TeamType) => (info: CellContext<EyeChartIndexRow, EyeChartCellEntry | undefined>) => (
    <p
      title={`${info.getValue()?.date} ${info.getValue()?.legacy_key}`}
      className={cx([
        classes[`${info.getValue()?.network?.toLocaleLowerCase()}`],
        classes[`${info.getValue()?.where}`],
        classes[
          `${info.getValue()?.slot_type ? `slot_${info.getValue()?.slot_type?.toLowerCase().replace(' ', '_')}` : ''}`
        ],
        classes[`${info.getValue()?.holiday ? `holiday_${info.getValue()?.holiday}` : ''}`],
        classes[`${info.getValue()?.double_header ? 'dh' : ''}`],
        classes[`${info.getValue()?.day}`],
        classes[`${info.getValue()?.week}`],
        classes[`${NFC_TEAM_KEYS.includes(team) ? 'nfc' : ''}`],
        classes[`${AFC_TEAM_KEYS.includes(team) ? 'afc' : ''}`],
      ])}
    >
      {' '}
      {info.getValue()?.opponent}
    </p>
  );

const columnGenerator = (teams: TeamType[]) =>
  teams.map((team) =>
    columnHelper.accessor(team, {
      cell: cellGenerator(team),
    })
  );

const columns = [
  columnHelper.display({
    id: 'week',
    cell: ({ row }) => row.original.week,
  }),
  columnHelper.group({
    header: 'NFC',
    columns: [
      columnHelper.group({
        header: 'EAST',
        columns: columnGenerator(TEAM_KEYS.slice(0, 4)),
      }),
      columnHelper.group({
        header: 'NORTH',
        columns: columnGenerator(TEAM_KEYS.slice(4, 8)),
      }),
      columnHelper.group({
        header: 'SOUTH',
        columns: columnGenerator(TEAM_KEYS.slice(8, 12)),
      }),
      columnHelper.group({
        header: 'WEST',
        columns: columnGenerator(TEAM_KEYS.slice(12, 16)),
      }),
    ],
  }),
  columnHelper.group({
    header: 'AFC',
    columns: [
      columnHelper.group({
        header: 'EAST',
        columns: columnGenerator(TEAM_KEYS.slice(16, 20)),
      }),
      columnHelper.group({
        header: 'NORTH',
        columns: columnGenerator(TEAM_KEYS.slice(20, 24)),
      }),
      columnHelper.group({
        header: 'SOUTH',
        columns: columnGenerator(TEAM_KEYS.slice(24, 28)),
      }),
      columnHelper.group({
        header: 'WEST',
        columns: columnGenerator(TEAM_KEYS.slice(28, 32)),
      }),
    ],
  }),
];

export function ScheduleEyeChart() {
  // const currentSchedule = useAppStore((state) => state.currentSchedule);
  const currentUpgradedSchedule = useAppStore((state) => state.currentUpgradedSchedule);
  const eyeChartIndex = useMemo(
    () => getEyeChartIndex(currentUpgradedSchedule),
    [currentUpgradedSchedule]
  );

  const table = useReactTable({
    data: eyeChartIndex,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {' '}
      <Flex
        align="center"
        direction="column"
        gap="sm"
        className={[sharedClasses.ubiquiaEyeChartContainer, classes.scheduleEyeChartContainer].join(
          ' '
        )}
      >
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
    </>
  );
}
