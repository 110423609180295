export const SCHEDULE_INDEX_MAP = {
  // 1: '3/6-16-LAC-KC Kickoff',
  // 2: '3/7 - 10b',
  // 3: '3/14 - TB Kickoff',
  // 4: '3/14 - CBS DH',
  // 5: '3/14 - NYJ Tgivs',
  // 6: '3/14 - NE xmas',
  // 7: '3/14 - Fox DH',
  // 8: 'test black friday',
  // 9: 'test bf - 1327',
  // 10: 'test bf - 1315',
  11: '3/20 - v20_soft_2_1315',
};

// All teams, in order.
export const TEAM_KEYS = [
  'DAL',
  'NYG',
  'PHI',
  'WAS',
  'CHI',
  'DET',
  'GB',
  'MIN',
  'ATL',
  'CAR',
  'NO',
  'TB',
  'AZ',
  'LAR',
  'SF',
  'SEA',
  'BUF',
  'MIA',
  'NE',
  'NYJ',
  'BAL',
  'CIN',
  'CLE',
  'PIT',
  'HOU',
  'IND',
  'JAC',
  'TEN',
  'DEN',
  'KC',
  'LV',
  'LAC',
] as const;

export const NFC_TEAM_KEYS: TeamType[] = [
  'DAL',
  'NYG',
  'PHI',
  'WAS',
  'CHI',
  'DET',
  'GB',
  'MIN',
  'ATL',
  'CAR',
  'NO',
  'TB',
  'AZ',
  'LAR',
  'SF',
  'SEA',
];

export const AFC_TEAM_KEYS: TeamType[] = [
  'BUF',
  'MIA',
  'NE',
  'NYJ',
  'BAL',
  'CIN',
  'CLE',
  'PIT',
  'HOU',
  'IND',
  'JAC',
  'TEN',
  'DEN',
  'KC',
  'LV',
  'LAC',
];

export const WEEK_KEYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18] as const;

export const WEEK_KEYS_EXTENDED = ['All', ...WEEK_KEYS] as const;

export const WEEK_KEYS_BYE = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14] as const;

export type WeekType = (typeof WEEK_KEYS)[number];

export type WeekExtendedType = (typeof WEEK_KEYS_EXTENDED)[number];

export type WeekByeType = (typeof WEEK_KEYS_BYE)[number];

// Type for importing a schedule from schedule.json
export type RawSchedule = {
  [key: string]: string[];
};

export type UpgradedLegacyKey = {
  // Not present for 'unknown' entries.
  network?: NetworkTypeSimple;
  day: DayType;
  week: WeekType;
  // Only present for old 'legacy' entries.
  legacy_key?: string;
  slot_type: SlotType;
  slot_name: string; // 'NBC Season Opener' - first column in spreadsheet
  holiday?: HolidaySlot;
  double_header: boolean;
  international: boolean;
  date: string;
};

export type UpgradedScheduleEntry = UpgradedLegacyKey & {
  home: TeamType;
  away: TeamType;
  // Not present for legacy entries.
};

export type TeamType = (typeof TEAM_KEYS)[number];

export type WhereType = 'home' | 'away';

export type MatchupEntry = {
  opponent: TeamType; // teams
  where: WhereType; // away/home
  networkKey: string; // ex: 'nbc.thu.1.prime
  day: string; // day of the week abbreviated
  network: string; // ex: 'nbc'
  slot: string; // ex: 'prime'
  week: WeekType;
};

// Final type used for the schedule grid.
export type MatchupIndex = {
  week: WeekType;
} & {
  [K in TeamType]?: MatchupEntry;
};

export const NETWORK_KEYS = [
  'Amz',
  'ESPN',
  'NFLN',
  'NBC',
  'Peacock',
  'CBS Single',
  'CBS Regional',
  'CBS DH',
  'Fox Single',
  'Fox Regional',
  'Fox DH',
  'Netflix',
] as const;

export const NETWORK_KEYS_SIMPLE = [
  'Amz',
  'ESPN',
  'NFLN',
  'NBC',
  'Peacock',
  'CBS',
  'Fox',
  'Netflix',
] as const;
export type NetworkTypeSimple = (typeof NETWORK_KEYS_SIMPLE)[number];

export const NETWORK_KEYS_SIMPLE_EXTENDED = ['All', ...NETWORK_KEYS_SIMPLE] as const;

export type NetworkTypeSimpleExtended = (typeof NETWORK_KEYS_SIMPLE_EXTENDED)[number];

export const NETWORK_KEYS_PRIMETIME = ['All', 'Amz', 'ESPN', 'NBC', 'NFLN'];

export type NetworkKeysPrimetimeType = (typeof NETWORK_KEYS_PRIMETIME)[number];

export type PercentageThresholdsIndex = {
  [networkKey in NetworkType]?: number;
};

export type NetworkType = (typeof NETWORK_KEYS)[number];

export const NETWORK_TYPE_COLOR_MAP: {
  [K in NetworkType]: string;
} = {
  'CBS DH': '#fd4141',
  'CBS Single': '#fd4141',
  'CBS Regional': '#fd4141',
  'Fox DH': '#137cd9',
  'Fox Single': '#137cd9',
  'Fox Regional': '#137cd9',
  Amz: '#8329f7',
  ESPN: '#ffa800',
  NFLN: '#929292',
  NBC: '#12785f',
  Peacock: '#0fb48c',
  Netflix: '#43465e',
};

export type NetworkSummaryIndexSimple = {
  network: NetworkTypeSimple;
} & {
  [K in TeamType]?: number;
};

export type RawNetworkSummarySimple = {
  [teamKey in TeamType]: {
    [networkKey in NetworkTypeSimple]?: number;
  };
};

// Final type used for the schedule grid.
export type NetworkSummaryIndex = {
  network: NetworkType;
} & {
  [K in TeamType]?: number;
};

export type RawNetworkSummary = {
  [teamKey in TeamType]: {
    [networkKey in NetworkType]?: number;
  };
};

export type SummaryHighlightsInfo = {
  Ratings: {
    Total: number;
  } & {
    [K in NetworkType]: number;
  };
  Growth: {
    Total: number;
  } & {
    [K in NetworkType]: number;
  };
  'Key Games': {
    'Season Opener': string;
    'International Games': string[];
    'Thanksgiving Games': string[];
    'Black Friday': string[];
    'Saturday before Christmas': string[];
    Christmas: string[];
  };
  'Team Rest': {
    '3A': string[];
    'Away after MNF Away': string;
    '2A to finish': string;
    '2A to start': string;
    'Rest Disparity < -15'?: string;
    'Division Series in First Half': string;
  };
  'Quality Score'?: number;
};

export type TeamSummaryEntry = {
  week: WeekType;
  network: NetworkType;
  opponent: TeamType;
  date: string;
  'time.of.day': string;
  'national.game': boolean;
  'matchup.rank': number;
};

export type TeamSummaryIndex = {
  [K in TeamType]: TeamSummaryEntry[];
};

export const MARKET_KEYS = ['New York', 'Los Angeles', 'Baltimore / DC'] as const;

export type MarketType = (typeof MARKET_KEYS)[number];

export type MarketsSummaryEntry = {
  week: WeekType;
  team_1: string;
  team_2: string;
};

export type MarketsSummaryIndex = {
  [K in MarketType]: {
    team_1: TeamType;
    team_2: TeamType;
    market_data: MarketsSummaryEntry[];
  };
};

export const MARKET_SUMMARY_TEAM_COLORS = {
  NYG: '#B51039',
  NYJ: '#1B5C46',
  LAC: '#0281C8',
  LAR: '#F8CC00',
  BAL: '#2B1F77',
  WAS: '#591514',
};

export type ViewershipPredictionsAverageEntry = {
  network: NetworkType;
  2023: number;
  2024: number;
};

export type ViewershipPredictionsDataRaw = {
  [networkKey in NetworkType]: {
    avg: {
      2023: number;
      2024: number;
    };
    weeks: {
      [weekKey in WeekType as string]: {
        [matchup: string]: { viewers: number; highlight: boolean };
      };
    };
  };
};

export type ViewershipPredictionsIndex = {
  viewership: ViewershipPredictionsRowEntry[];
  averages: ViewershipPredictionsAverageEntry[];
};

export enum ConstraintType {
  STADIUM_BLOCK = 'stadium_block',
  MATCHUP_GUARANTEE = 'matchup_guarantee',
  TEAM_GUARANTEE = 'team_guarantee',
  BYE_GUARANTEE = 'bye_guarantee',
  HOME_AWAY_GUARANTEE = 'home_away_guarantee',
  PRIMETIME_MIN_MAX = 'primetime_min_max',
}

export const ConstraintTypeTranslations: {
  [K in ConstraintType]: string;
} = {
  [ConstraintType.STADIUM_BLOCK]: 'Stadium Block',
  [ConstraintType.MATCHUP_GUARANTEE]: 'Matchup Guarantee',
  [ConstraintType.TEAM_GUARANTEE]: 'Team Guarantee',
  [ConstraintType.BYE_GUARANTEE]: 'Bye Guarantee',
  [ConstraintType.HOME_AWAY_GUARANTEE]: 'Home/Away Guarantee',
  [ConstraintType.PRIMETIME_MIN_MAX]: 'Primetime Min/Max',
};

export const DAYS_IN_ORDER = ['Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon'];

export enum ConstraintDay {
  WEDNESDAY = 'Wed',
  THURSDAY = 'Thu',
  FRIDAY = 'Fri',
  SATURDAY = 'Sat',
  SUNDAY = 'Sun',
  MONDAY = 'Mon',
}

export const ConstraintDayTranslations: {
  [K in ConstraintDay]: string;
} = {
  [ConstraintDay.WEDNESDAY]: 'Wednesday',
  [ConstraintDay.THURSDAY]: 'Thursday',
  [ConstraintDay.FRIDAY]: 'Friday',
  [ConstraintDay.SATURDAY]: 'Saturday',
  [ConstraintDay.SUNDAY]: 'Sunday',
  [ConstraintDay.MONDAY]: 'Monday',
};

export const SLOTS_IN_ORDER = [
  'all',
  'tnf',
  'saturday',
  'sun_1',
  'sun_4',
  'snf',
  'mnf',
  'christmas',
  'tgiv',
];

export enum ConstraintSlot {
  ALL = 'all',
  TNF = 'tnf',
  SATURDAY = 'saturday',
  SUN_1 = 'sun_1',
  SUN_4 = 'sun_4',
  SNF = 'snf',
  MNF = 'mnf',
  CHRISTMAS = 'christmas',
  TGIV = 'tgiv',
  BLACK_FRIDAY = 'black_friday',
}

export const ConstraintSlotTranslations: {
  [K in ConstraintSlot]: string;
} = {
  [ConstraintSlot.ALL]: 'All',
  [ConstraintSlot.TNF]: 'TNF',
  [ConstraintSlot.SATURDAY]: 'Saturday',
  [ConstraintSlot.SUN_1]: 'Sunday 1pm',
  [ConstraintSlot.SUN_4]: 'Sunday 4pm',
  [ConstraintSlot.SNF]: 'SNF',
  [ConstraintSlot.MNF]: 'MNF',
  [ConstraintSlot.CHRISTMAS]: 'Christmas',
  [ConstraintSlot.TGIV]: 'Thanksgiving',
  [ConstraintSlot.BLACK_FRIDAY]: 'Black Friday',
};

export enum HolidaySlot {
  CHRISTMAS = 'christmas',
  TGIV = 'tgiv',
  BLACK_FRIDAY = 'black_friday',
}

export const HolidaySlotTranslations: {
  [K in HolidaySlot]: string;
} = {
  [HolidaySlot.CHRISTMAS]: 'Christmas',
  [HolidaySlot.TGIV]: 'Thanksgiving',
  [HolidaySlot.BLACK_FRIDAY]: 'Black Friday',
};

export type ViewershipPredictionsRowEntry = {
  week: WeekType;
} & {
  [networkKey in NetworkType]: {
    matchup: string;
    viewers: number;
    highlight: boolean;
  }[];
};

export type ConstraintEntry =
  | StadiumBlock
  | MatchupGuarantee
  | TeamGuarantee
  | ByeGuarantee
  | HomeAwayGuarantee
  | PrimetimeMinMax;

export type StadiumBlock = {
  type: ConstraintType.STADIUM_BLOCK;
  params: {
    team: TeamType;
    week: WeekType;
    slot: ConstraintSlot[];
  };
};

export type MatchupGuarantee = {
  type: ConstraintType.MATCHUP_GUARANTEE;
  params: {
    matchup: string;
    week: WeekExtendedType[];
    network: NetworkTypeSimple[];
    inclusive: boolean;
    holiday_slot?: HolidaySlot;
  };
};

export type TeamGuarantee = {
  type: ConstraintType.TEAM_GUARANTEE;
  params: {
    team: TeamType[];
    week: WeekExtendedType[];
    network: NetworkTypeSimple[];
    min_appearances: number;
    max_appearances: number;
    holiday_slot?: HolidaySlot;
  };
};

export type ByeGuarantee = {
  type: ConstraintType.BYE_GUARANTEE;
  params: {
    team: TeamType;
    week: WeekByeType[];
    inclusive: boolean;
  };
};

export type HomeAwayGuarantee = {
  type: ConstraintType.HOME_AWAY_GUARANTEE;
  params: {
    team: TeamType[];
    home_away: 'home' | 'away';
    week: WeekExtendedType[];
    network: NetworkTypeSimpleExtended[];
    holiday_slot?: HolidaySlot;
    min_appearances: number;
    max_appearances: number;
  };
};

export type PrimetimeMinMax = {
  type: ConstraintType.PRIMETIME_MIN_MAX;
  params: {
    team: TeamType[];
    network: NetworkKeysPrimetimeType[];
    min_appearances: number;
    max_appearances: number;
  };
};

export type StaticConstraintEntry = {
  description: string;
  summary: string;
};

export type WeekSummaryEntry = {
  'day.of.week': ConstraintDay;
  'time.of.day': string;
  network: NetworkType;
  matchup: string;
  rating: number;
};

export type WeekSummaryIndex = {
  [K in Exclude<WeekType, 18>]: WeekSummaryEntry[];
};

export type ScheduleListEntry = {
  id: string;
  name: string;
  created_at: string;
  job_id: string;
  is_valid: boolean;
};

export type JobListEntry = {
  id: string;
  status: string;
  created_at: string;
};

export type BuildListEntry = {
  id: string;
  user: string;
  jobs: JobListEntry[];
  results: ScheduleListEntry[];
  created_at: string;
  name: string;
};

export type UploadsListEntry = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  file: string;
  user: string;
  results_names: string[];
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const TEAM_MATCHUPS = [
  'ATL-CAR',
  'ATL-DEN',
  'ATL-LV',
  'ATL-MIN',
  'ATL-NO',
  'ATL-PHI',
  'ATL-TB',
  'ATL-WAS',
  'AZ-BUF',
  'AZ-CAR',
  'AZ-GB',
  'AZ-LAR',
  'AZ-MIA',
  'AZ-MIN',
  'AZ-SEA',
  'AZ-SF',
  'BAL-CIN',
  'BAL-CLE',
  'BAL-DAL',
  'BAL-HOU',
  'BAL-KC',
  'BAL-LAC',
  'BAL-NYG',
  'BAL-PIT',
  'BAL-TB',
  'BUF-BAL',
  'BUF-DET',
  'BUF-HOU',
  'BUF-IND',
  'BUF-LAR',
  'BUF-MIA',
  'BUF-NE',
  'BUF-NYJ',
  'BUF-SEA',
  'CAR-ATL',
  'CAR-CHI',
  'CAR-DEN',
  'CAR-LV',
  'CAR-NO',
  'CAR-PHI',
  'CAR-TB',
  'CAR-WAS',
  'CHI-AZ',
  'CHI-DET',
  'CHI-GB',
  'CHI-HOU',
  'CHI-IND',
  'CHI-MIN',
  'CHI-SF',
  'CHI-WAS',
  'CIN-BAL',
  'CIN-CAR',
  'CIN-CLE',
  'CIN-DAL',
  'CIN-KC',
  'CIN-LAC',
  'CIN-NYG',
  'CIN-PIT',
  'CIN-TEN',
  'CLE-BAL',
  'CLE-CIN',
  'CLE-DEN',
  'CLE-JAC',
  'CLE-LV',
  'CLE-NO',
  'CLE-PHI',
  'CLE-PIT',
  'CLE-WAS',
  'DAL-ATL',
  'DAL-CAR',
  'DAL-CLE',
  'DAL-NYG',
  'DAL-PHI',
  'DAL-PIT',
  'DAL-SF',
  'DAL-WAS',
  'DEN-BAL',
  'DEN-CIN',
  'DEN-KC',
  'DEN-LAC',
  'DEN-LV',
  'DEN-NO',
  'DEN-NYJ',
  'DEN-SEA',
  'DEN-TB',
  'DET-AZ',
  'DET-CHI',
  'DET-DAL',
  'DET-GB',
  'DET-HOU',
  'DET-IND',
  'DET-MIN',
  'DET-SF',
  'GB-CHI',
  'GB-DET',
  'GB-JAC',
  'GB-LAR',
  'GB-MIN',
  'GB-PHI',
  'GB-SEA',
  'GB-TEN',
  'HOU-DAL',
  'HOU-GB',
  'HOU-IND',
  'HOU-JAC',
  'HOU-KC',
  'HOU-MIN',
  'HOU-NE',
  'HOU-NYJ',
  'HOU-TEN',
  'IND-DEN',
  'IND-GB',
  'IND-HOU',
  'IND-JAC',
  'IND-MIN',
  'IND-NE',
  'IND-NYG',
  'IND-NYJ',
  'IND-TEN',
  'JAC-BUF',
  'JAC-CHI',
  'JAC-DET',
  'JAC-HOU',
  'JAC-IND',
  'JAC-LV',
  'JAC-MIA',
  'JAC-PHI',
  'JAC-TEN',
  'KC-ATL',
  'KC-BUF',
  'KC-CAR',
  'KC-CLE',
  'KC-DEN',
  'KC-LAC',
  'KC-LV',
  'KC-PIT',
  'KC-SF',
  'LAC-ATL',
  'LAC-AZ',
  'LAC-CAR',
  'LAC-CLE',
  'LAC-DEN',
  'LAC-KC',
  'LAC-LV',
  'LAC-NE',
  'LAC-PIT',
  'LAR-AZ',
  'LAR-CHI',
  'LAR-DET',
  'LAR-NE',
  'LAR-NO',
  'LAR-NYJ',
  'LAR-SEA',
  'LAR-SF',
  'LV-BAL',
  'LV-CIN',
  'LV-DEN',
  'LV-KC',
  'LV-LAC',
  'LV-LAR',
  'LV-MIA',
  'LV-NO',
  'LV-TB',
  'MIA-BUF',
  'MIA-CLE',
  'MIA-GB',
  'MIA-HOU',
  'MIA-IND',
  'MIA-LAR',
  'MIA-NE',
  'MIA-NYJ',
  'MIA-SEA',
  'MIN-CHI',
  'MIN-DET',
  'MIN-GB',
  'MIN-JAC',
  'MIN-LAR',
  'MIN-NYG',
  'MIN-SEA',
  'MIN-TEN',
  'NE-AZ',
  'NE-BUF',
  'NE-CHI',
  'NE-CIN',
  'NE-JAC',
  'NE-MIA',
  'NE-NYJ',
  'NE-SF',
  'NE-TEN',
  'NO-ATL',
  'NO-CAR',
  'NO-DAL',
  'NO-GB',
  'NO-KC',
  'NO-LAC',
  'NO-NYG',
  'NO-TB',
  'NYG-ATL',
  'NYG-CAR',
  'NYG-CLE',
  'NYG-DAL',
  'NYG-PHI',
  'NYG-PIT',
  'NYG-SEA',
  'NYG-WAS',
  'NYJ-AZ',
  'NYJ-BUF',
  'NYJ-JAC',
  'NYJ-MIA',
  'NYJ-MIN',
  'NYJ-NE',
  'NYJ-PIT',
  'NYJ-SF',
  'NYJ-TEN',
  'PHI-BAL',
  'PHI-CIN',
  'PHI-DAL',
  'PHI-LAR',
  'PHI-NO',
  'PHI-NYG',
  'PHI-TB',
  'PHI-WAS',
  'PIT-ATL',
  'PIT-BAL',
  'PIT-CIN',
  'PIT-CLE',
  'PIT-DEN',
  'PIT-IND',
  'PIT-LV',
  'PIT-PHI',
  'PIT-WAS',
  'SEA-ATL',
  'SEA-AZ',
  'SEA-CHI',
  'SEA-DET',
  'SEA-LAR',
  'SEA-NE',
  'SEA-NYJ',
  'SEA-SF',
  'SF-AZ',
  'SF-BUF',
  'SF-GB',
  'SF-LAR',
  'SF-MIA',
  'SF-MIN',
  'SF-SEA',
  'SF-TB',
  'TB-ATL',
  'TB-CAR',
  'TB-DAL',
  'TB-DET',
  'TB-KC',
  'TB-LAC',
  'TB-NO',
  'TB-NYG',
  'TEN-BUF',
  'TEN-CHI',
  'TEN-DET',
  'TEN-HOU',
  'TEN-IND',
  'TEN-JAC',
  'TEN-LAC',
  'TEN-MIA',
  'TEN-WAS',
  'WAS-AZ',
  'WAS-BAL',
  'WAS-CIN',
  'WAS-DAL',
  'WAS-NO',
  'WAS-NYG',
  'WAS-PHI',
  'WAS-TB',
];

export type AnalysisIndex = {
  cbs_total: number;
  fox_total: number;
  dh_game_in_la: Array<{
    week: number;
    network: 'CBS' | 'Fox';
    category: string;
    la_matchup: string;
    doubleheader_matchup: string;
  }>;
  dh_game_in_ny: Array<{
    week: number;
    network: 'CBS' | 'Fox';
    ny_matchup: string;
    doubleheader_matchup: string;
  }>;
  rest_disparity: Partial<Record<TeamType, { favorable: number; unfavorable: number }>>;
  dh_ny_by_network: Record<'CBS' | 'Fox', number>;
  dh_la_by_category: Record<
    | 'CBS could air'
    | 'CBS unopposed'
    | 'Fox could air'
    | 'Fox unopposed'
    | 'CBS doesnt air'
    | 'Fox doesnt air',
    number
  >;
  two_away_to_start: Partial<Record<TeamType, string[]>>;
  sf_lv_at_same_time: Record<number, string[]>;
  two_away_to_finish: Partial<Record<TeamType, string[]>>;
  games_after_bye_week: Partial<Record<TeamType, { away: number; home: number }>>;
  lac_lar_at_same_time: Record<number, string[]>;
  nyg_nyj_at_same_time: Record<number, string[]>;
  three_game_road_trip: Partial<Record<TeamType, string[]>>;
  road_after_road_monday: Partial<Record<TeamType, string[]>>;
  espn_division_games_count: number;
  finish_three_of_four_away: Partial<Record<TeamType, string[]>>;
  potential_bal_was_conflict: Record<string, never>;
  unfavorable_rest_disparity: Array<{ team: TeamType; favorable: number; unfavorable: number }>;
  division_games_count_by_week: Record<number, number>;
  teams_by_espn_appearance_count: Record<number, TeamType[]>;
  away_before_home_thursday_friday: Partial<Record<TeamType, string[]>>;
  primary_dh_game_doesnt_air_in_dc: Array<{
    week: number;
    dc_matchup: string;
    doubleheader_matchup: string;
  }>;
};

export type AnalysisTableRow = {
  section: string;
  description: string;
};

export const ANALYSIS_INDEX: AnalysisIndex = {
  cbs_total: 92,
  fox_total: 92,
  dh_game_in_la: [
    {
      week: 1,
      network: 'CBS',
      category: 'CBS could air',
      la_matchup: 'TEN-HOU',
      doubleheader_matchup: 'DAL-CLE',
    },
    {
      week: 2,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'CIN-KC',
      doubleheader_matchup: 'CIN-KC',
    },
    {
      week: 3,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'BAL-DAL',
      doubleheader_matchup: 'BAL-DAL',
    },
    {
      week: 4,
      network: 'CBS',
      category: 'CBS unopposed',
      la_matchup: 'KC-LAC',
      doubleheader_matchup: 'KC-LAC',
    },
    {
      week: 5,
      network: 'CBS',
      category: 'CBS could air',
      la_matchup: 'NYG-SEA',
      doubleheader_matchup: 'PHI-CIN',
    },
    {
      week: 6,
      network: 'Fox',
      category: 'Fox could air',
      la_matchup: 'PIT-LV',
      doubleheader_matchup: 'SF-MIA',
    },
    {
      week: 7,
      network: 'CBS',
      category: 'CBS could air',
      la_matchup: 'KC-LV',
      doubleheader_matchup: 'GB-LAR',
    },
    {
      week: 7,
      network: 'Fox',
      category: 'Fox doesnt air',
      la_matchup: 'NE-AZ',
      doubleheader_matchup: '',
    },
    {
      week: 8,
      network: 'CBS',
      category: 'CBS doesnt air',
      la_matchup: 'DET-AZ',
      doubleheader_matchup: '',
    },
    {
      week: 8,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'KC-SF',
      doubleheader_matchup: 'KC-SF',
    },
    {
      week: 9,
      network: 'CBS',
      category: 'CBS unopposed',
      la_matchup: 'DAL-PHI',
      doubleheader_matchup: 'DAL-PHI',
    },
    {
      week: 10,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'GB-DET',
      doubleheader_matchup: 'GB-DET',
    },
    {
      week: 11,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'KC-BUF',
      doubleheader_matchup: 'KC-BUF',
    },
    {
      week: 12,
      network: 'CBS',
      category: 'CBS could air',
      la_matchup: 'CLE-LV',
      doubleheader_matchup: 'DET-DAL',
    },
    {
      week: 12,
      network: 'Fox',
      category: 'Fox doesnt air',
      la_matchup: 'LAR-SEA',
      doubleheader_matchup: '',
    },
    {
      week: 13,
      network: 'CBS',
      category: 'CBS could air',
      la_matchup: 'LAR-AZ',
      doubleheader_matchup: 'PHI-BAL',
    },
    {
      week: 14,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'BUF-LAR',
      doubleheader_matchup: 'BUF-LAR',
    },
    {
      week: 15,
      network: 'CBS',
      category: 'CBS unopposed',
      la_matchup: 'BUF-DET',
      doubleheader_matchup: 'BUF-DET',
    },
    {
      week: 15,
      network: 'Fox',
      category: 'Fox could air',
      la_matchup: 'DEN-LAC',
      doubleheader_matchup: 'PIT-PHI',
    },
    {
      week: 16,
      network: 'CBS',
      category: 'CBS could air',
      la_matchup: 'NYJ-MIA',
      doubleheader_matchup: 'SF-GB',
    },
    {
      week: 16,
      network: 'Fox',
      category: 'Fox doesnt air',
      la_matchup: 'CAR-DEN',
      doubleheader_matchup: '',
    },
    {
      week: 17,
      network: 'Fox',
      category: 'Fox unopposed',
      la_matchup: 'PHI-DAL',
      doubleheader_matchup: 'PHI-DAL',
    },
  ],
  dh_game_in_ny: [
    {
      week: 1,
      network: 'CBS',
      ny_matchup: 'NE-CHI',
      doubleheader_matchup: 'DAL-CLE',
    },
    {
      week: 1,
      network: 'Fox',
      ny_matchup: 'DEN-SEA',
      doubleheader_matchup: '',
    },
    {
      week: 2,
      network: 'Fox',
      ny_matchup: 'CIN-KC',
      doubleheader_matchup: 'CIN-KC',
    },
    {
      week: 3,
      network: 'Fox',
      ny_matchup: 'BAL-DAL',
      doubleheader_matchup: 'BAL-DAL',
    },
    {
      week: 4,
      network: 'CBS',
      ny_matchup: 'KC-LAC',
      doubleheader_matchup: 'KC-LAC',
    },
    {
      week: 5,
      network: 'CBS',
      ny_matchup: 'NYG-SEA',
      doubleheader_matchup: 'PHI-CIN',
    },
    {
      week: 6,
      network: 'Fox',
      ny_matchup: 'PIT-LV',
      doubleheader_matchup: 'SF-MIA',
    },
    {
      week: 7,
      network: 'CBS',
      ny_matchup: 'GB-LAR',
      doubleheader_matchup: 'GB-LAR',
    },
    {
      week: 8,
      network: 'CBS',
      ny_matchup: 'DET-AZ',
      doubleheader_matchup: '',
    },
    {
      week: 8,
      network: 'Fox',
      ny_matchup: 'KC-SF',
      doubleheader_matchup: 'KC-SF',
    },
    {
      week: 9,
      network: 'CBS',
      ny_matchup: 'DAL-PHI',
      doubleheader_matchup: 'DAL-PHI',
    },
    {
      week: 10,
      network: 'Fox',
      ny_matchup: 'SF-SEA',
      doubleheader_matchup: 'GB-DET',
    },
    {
      week: 11,
      network: 'Fox',
      ny_matchup: 'KC-BUF',
      doubleheader_matchup: 'KC-BUF',
    },
    {
      week: 12,
      network: 'CBS',
      ny_matchup: 'DET-DAL',
      doubleheader_matchup: 'DET-DAL',
    },
    {
      week: 12,
      network: 'Fox',
      ny_matchup: 'LAR-SEA',
      doubleheader_matchup: '',
    },
    {
      week: 13,
      network: 'CBS',
      ny_matchup: 'LAR-AZ',
      doubleheader_matchup: 'PHI-BAL',
    },
    {
      week: 14,
      network: 'Fox',
      ny_matchup: 'BUF-LAR',
      doubleheader_matchup: 'BUF-LAR',
    },
    {
      week: 15,
      network: 'CBS',
      ny_matchup: 'BUF-DET',
      doubleheader_matchup: 'BUF-DET',
    },
    {
      week: 15,
      network: 'Fox',
      ny_matchup: 'DEN-LAC',
      doubleheader_matchup: 'PIT-PHI',
    },
    {
      week: 16,
      network: 'CBS',
      ny_matchup: 'NYJ-MIA',
      doubleheader_matchup: 'SF-GB',
    },
    {
      week: 17,
      network: 'CBS',
      ny_matchup: 'WAS-AZ',
      doubleheader_matchup: '',
    },
    {
      week: 17,
      network: 'Fox',
      ny_matchup: 'PHI-DAL',
      doubleheader_matchup: 'PHI-DAL',
    },
  ],
  rest_disparity: {
    AZ: {
      favorable: 5,
      unfavorable: 3,
    },
    GB: {
      favorable: 4,
      unfavorable: 4,
    },
    KC: {
      favorable: 5,
      unfavorable: 3,
    },
    LV: {
      favorable: 4,
      unfavorable: 4,
    },
    NE: {
      favorable: 2,
      unfavorable: 2,
    },
    NO: {
      favorable: 2,
      unfavorable: 2,
    },
    SF: {
      favorable: 3,
      unfavorable: 4,
    },
    TB: {
      favorable: 3,
      unfavorable: 4,
    },
    ATL: {
      favorable: 5,
      unfavorable: 1,
    },
    BAL: {
      favorable: 5,
      unfavorable: 2,
    },
    BUF: {
      favorable: 3,
      unfavorable: 3,
    },
    CAR: {
      favorable: 2,
      unfavorable: 2,
    },
    CHI: {
      favorable: 3,
      unfavorable: 2,
    },
    CIN: {
      favorable: 3,
      unfavorable: 5,
    },
    CLE: {
      favorable: 2,
      unfavorable: 6,
    },
    DAL: {
      favorable: 4,
      unfavorable: 3,
    },
    DEN: {
      favorable: 2,
      unfavorable: 5,
    },
    DET: {
      favorable: 3,
      unfavorable: 5,
    },
    HOU: {
      favorable: 3,
      unfavorable: 2,
    },
    IND: {
      favorable: 2,
      unfavorable: 2,
    },
    JAC: {
      favorable: 2,
      unfavorable: 1,
    },
    LAC: {
      favorable: 4,
      unfavorable: 4,
    },
    LAR: {
      favorable: 2,
      unfavorable: 3,
    },
    MIA: {
      favorable: 2,
      unfavorable: 5,
    },
    MIN: {
      favorable: 4,
      unfavorable: 4,
    },
    NYG: {
      favorable: 4,
      unfavorable: 2,
    },
    NYJ: {
      favorable: 5,
      unfavorable: 2,
    },
    PHI: {
      favorable: 4,
      unfavorable: 4,
    },
    PIT: {
      favorable: 1,
      unfavorable: 5,
    },
    SEA: {
      favorable: 3,
      unfavorable: 4,
    },
    TEN: {
      favorable: 3,
      unfavorable: 4,
    },
    WAS: {
      favorable: 4,
      unfavorable: 1,
    },
  },
  dh_ny_by_network: {
    CBS: 11,
    Fox: 11,
  },
  dh_la_by_category: {
    'CBS could air': 6,
    'CBS unopposed': 3,
    'Fox could air': 2,
    'Fox unopposed': 7,
    'CBS doesnt air': 1,
    'Fox doesnt air': 3,
  },
  two_away_to_start: {
    TB: ['@ ATL', '@ NYG'],
    JAC: ['@ MIA', '@ DET'],
    LAR: ['@ DET', '@ NO'],
    NYJ: ['@ SF', '@ TEN'],
    PIT: ['@ IND', '@ DEN'],
    WAS: ['@ CIN', '@ DAL'],
  },
  sf_lv_at_same_time: {
    6: ['SF@MIA on Fox', 'PIT@LV on Fox'],
  },
  two_away_to_finish: {
    KC: ['@ PIT', '@ DEN'],
    LAC: ['@ NE', '@ LV'],
    MIA: ['@ CLE', '@ NE'],
    NYG: ['@ ATL', '@ PHI'],
    SEA: ['@ CHI', '@ LAR'],
  },
  games_after_bye_week: {
    AZ: {
      away: 1,
      home: 1,
    },
    GB: {
      away: 0,
      home: 0,
    },
    KC: {
      away: 1,
      home: 2,
    },
    LV: {
      away: 0,
      home: 1,
    },
    NE: {
      away: 0,
      home: 1,
    },
    NO: {
      away: 1,
      home: 0,
    },
    SF: {
      away: 0,
      home: 0,
    },
    TB: {
      away: 1,
      home: 0,
    },
    ATL: {
      away: 0,
      home: 1,
    },
    BAL: {
      away: 0,
      home: 0,
    },
    BUF: {
      away: 0,
      home: 0,
    },
    CAR: {
      away: 0,
      home: 0,
    },
    CHI: {
      away: 0,
      home: 0,
    },
    CIN: {
      away: 1,
      home: 0,
    },
    CLE: {
      away: 2,
      home: 0,
    },
    DAL: {
      away: 0,
      home: 1,
    },
    DEN: {
      away: 0,
      home: 0,
    },
    DET: {
      away: 0,
      home: 2,
    },
    HOU: {
      away: 1,
      home: 0,
    },
    IND: {
      away: 0,
      home: 0,
    },
    JAC: {
      away: 1,
      home: 0,
    },
    LAC: {
      away: 0,
      home: 0,
    },
    LAR: {
      away: 0,
      home: 0,
    },
    MIA: {
      away: 1,
      home: 2,
    },
    MIN: {
      away: 1,
      home: 0,
    },
    NYG: {
      away: 0,
      home: 1,
    },
    NYJ: {
      away: 1,
      home: 0,
    },
    PHI: {
      away: 0,
      home: 2,
    },
    PIT: {
      away: 1,
      home: 1,
    },
    SEA: {
      away: 0,
      home: 0,
    },
    TEN: {
      away: 0,
      home: 0,
    },
    WAS: {
      away: 0,
      home: 0,
    },
  },
  lac_lar_at_same_time: {},
  nyg_nyj_at_same_time: {
    14: ['HOU@NYJ on CBS', 'NYG@PIT on Fox'],
  },
  three_game_road_trip: {
    GB: ['@ JAC', '@ TEN', '@ LAR'],
    NE: ['@ AZ', '@ JAC', '@ CIN'],
    CIN: ['@ LAC', '@ NYG', '@ CAR'],
    LAR: ['@ CHI', '@ SEA', '@ AZ'],
    MIN: ['@ SEA', '@ CHI', '@ GB'],
  },
  road_after_road_monday: {
    NO: ['@ TB', '@ ATL'],
    CIN: ['@ DAL', '@ TEN'],
    HOU: ['@ DAL', '@ MIN'],
    NYJ: ['@ SF', '@ TEN'],
  },
  espn_division_games_count: 5,
  finish_three_of_four_away: {
    KC: ['@ DEN', '@ PIT', 'HOU', '@ CLE'],
    NO: ['@ CAR', 'LV', '@ ATL', '@ TB'],
    CHI: ['@ GB', 'SEA', '@ WAS', '@ AZ'],
    MIA: ['@ NE', '@ CLE', 'NYJ', '@ HOU'],
    MIN: ['@ DET', 'GB', '@ LAR', '@ JAC'],
    PIT: ['@ CLE', 'KC', '@ BAL', '@ PHI'],
  },
  potential_bal_was_conflict: {},
  unfavorable_rest_disparity: [
    {
      team: 'PIT',
      favorable: 1,
      unfavorable: 5,
    },
    {
      team: 'DEN',
      favorable: 2,
      unfavorable: 5,
    },
    {
      team: 'CLE',
      favorable: 2,
      unfavorable: 6,
    },
    {
      team: 'MIA',
      favorable: 2,
      unfavorable: 5,
    },
  ],
  division_games_count_by_week: {
    1: 2,
    2: 3,
    3: 8,
    4: 7,
    7: 4,
    8: 3,
    9: 7,
    10: 10,
    11: 3,
    12: 2,
    13: 7,
    14: 7,
    15: 4,
    16: 9,
    17: 4,
    18: 16,
  },
  teams_by_espn_appearance_count: {
    0: ['AZ', 'CAR', 'WAS'],
    1: [
      'CHI',
      'CIN',
      'CLE',
      'DEN',
      'GB',
      'JAC',
      'LV',
      'MIA',
      'NE',
      'NYG',
      'NYJ',
      'PHI',
      'PIT',
      'SEA',
      'IND',
      'TEN',
    ],
    2: ['ATL', 'BAL', 'BUF', 'DAL', 'DET', 'HOU', 'KC', 'LAC', 'LAR', 'MIN', 'NO', 'SF', 'TB'],
  },
  away_before_home_thursday_friday: {
    GB: ['@ CHI', 'MIA'],
    KC: ['@ CAR', 'LV'],
    BAL: ['@ TB', 'DEN'],
    CHI: ['@ WAS', 'SEA'],
    CIN: ['@ TEN', 'CLE'],
    DET: ['@ DAL', 'CHI'],
    HOU: ['@ MIN', 'IND'],
    JAC: ['@ TEN', 'NYJ'],
    NYG: ['@ WAS', 'DAL'],
    NYJ: ['@ TEN', 'NE'],
  },
  primary_dh_game_doesnt_air_in_dc: [
    {
      week: 1,
      dc_matchup: 'NE-CHI',
      doubleheader_matchup: 'DAL-CLE',
    },
    {
      week: 2,
      dc_matchup: 'PIT-DEN',
      doubleheader_matchup: 'CIN-KC',
    },
    {
      week: 3,
      dc_matchup: 'DEN-LV',
      doubleheader_matchup: 'BAL-DAL',
    },
    {
      week: 5,
      dc_matchup: 'NYG-SEA',
      doubleheader_matchup: 'PHI-CIN',
    },
    {
      week: 6,
      dc_matchup: 'PIT-LV',
      doubleheader_matchup: 'SF-MIA',
    },
    {
      week: 10,
      dc_matchup: 'SF-SEA',
      doubleheader_matchup: 'GB-DET',
    },
    {
      week: 13,
      dc_matchup: 'LAR-AZ',
      doubleheader_matchup: 'PHI-BAL',
    },
    {
      week: 15,
      dc_matchup: 'DEN-LAC',
      doubleheader_matchup: 'PIT-PHI',
    },
    {
      week: 16,
      dc_matchup: 'AZ-SEA',
      doubleheader_matchup: 'SF-GB',
    },
  ],
};

export type FullScheduleEntry = {
  id: string;
  build_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  is_notified: boolean;
  excel_schedule: string;
  results: {
    schedule: RawSchedule | UpgradedScheduleEntry[];
    summary_teams: TeamSummaryIndex;
    summary_weeks: WeekSummaryIndex;
    summary_highlights: SummaryHighlightsInfo;
    summary_network_games: ViewershipPredictionsDataRaw;
    summary_network_teams: RawNetworkSummarySimple;
    summary_bye_thurs: RawByeThursSummary;
    summary_rest: RawRestSummary;
    summary_markets?: MarketsSummaryIndex;
    analysis?: AnalysisIndex;
  };
};

export type BuildContext = {
  constraints: ConstraintEntry[];
  // TODO(maciek): Rename these to use snake case.
  percentageThresholds: PercentageThresholdsIndex;
  baseBuildIds: string[];
  warm_start_id: string | null;
};

export type FullBuildEntry = {
  context: BuildContext;
  created_at: string;
  id: string;
  jobs: JobListEntry[];
  name: string;
  results: ScheduleListEntry[];
  updated_at: string;
  user: string;
};

export type JobManagerStatus = 'Pending' | 'Running' | 'Completed' | 'Failed';

export type JobManagerEntry = {
  name: string;
  date_submitted: string;
  date_completed: string;
  status: JobManagerStatus;
};

export type PostConstraintsResponse = {
  id: string;
  user: string;
  jobs: string[];
  created_at: string;
  updated_at: string;
  name: string;
  context: { constraints: ConstraintEntry[]; percentageThresholds: PercentageThresholdsIndex };
  seed_schedule_id: string | null;
};

export type UploadsPostResponse = {
  id: string;
  name: string;
  file: string;
  user: string;
  created_at: string;
  updated_at: string;
  results_names: string[];
};

export type UserInfoResponse = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};

export type RawByeThursSummary = {
  [teamKey in TeamType]: {
    bye: number[];
    'thurs/fri': number[];
  };
};

export type ByeThursIndexEntry = {
  type: 'bye' | 'thurs/fri';
} & {
  [K in TeamType]?: number[];
};

export type ByeThursIndex = ByeThursIndexEntry[];

export type RestSummaryValuesIndex = {
  total: number;
} & {
  [weekKey in keyof typeof WEEK_KEYS]: number;
};

export type RawRestSummary = {
  [teamKey in TeamType | 'total']: RestSummaryValuesIndex;
};

export type RestSummaryEntry = {
  week: keyof typeof WEEK_KEYS | 'total';
} & {
  [K in TeamType]?: number;
};

export type RestSummaryIndex = RestSummaryEntry[];

// New consts for the upgraded schedule eye chart.
export const DAY_KEYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const;
export type DayType = (typeof DAY_KEYS)[number];

export const LEGACY_SLOT_KEYS = ['e', 'l', 'dh', 'prime', 'int'] as const;
export type LegacySlotType = (typeof LEGACY_SLOT_KEYS)[number];

export const SLOT_TYPE_KEYS = [
  'CBS Early',
  'CBS Late',
  'Fox Early',
  'Fox Late',
  'NBC',
  'ESPN',
  'Prime',
  'Netflix',
  'Peacock',
  'International',
  'NFLN',
  'custom',
] as const;
export type SlotType = (typeof SLOT_TYPE_KEYS)[number];

export const LegacyNetworkKeyToNetworkKeysSimpleMap: Record<string, NetworkTypeSimple> = {
  amz: 'Amz',
  espn: 'ESPN',
  nfln: 'NFLN',
  nbc: 'NBC',
  netflix: 'Netflix',
  peacock: 'Peacock',
  cbs: 'CBS',
  fox: 'Fox',
};

export type EyeChartCellEntry = {
  opponent: TeamType; // teams
  where: WhereType; // away/home
  day: DayType; // day of the week abbreviated
  network?: NetworkTypeSimple; // ex: 'nbc'
  legacy_slot?: LegacySlotType; // ex: 'prime'
  holiday?: HolidaySlot;
  double_header: boolean;
  slot_type: SlotType;
  legacy_key?: string;
  week: WeekType;
  date: string;
};

// Final type used for the schedule grid.
export type EyeChartIndexRow = {
  week: WeekType;
} & {
  [K in TeamType]?: EyeChartCellEntry;
};

export type EyeChartIndex = EyeChartIndexRow[];
