import { useEffect, useMemo, useState } from 'react';
import {
  Group,
  Button,
  Flex,
  Title,
  Box,
  ActionIcon,
  rem,
  Tooltip,
  Text,
  Modal,
  Stack,
} from '@mantine/core';
import {
  useMantineReactTable,
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
} from 'mantine-react-table';

import { RecursivePartial } from 'utils/scheduleConsts';
import { useAppStore } from 'stores/appStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faCircleMinus } from '@fortawesome/sharp-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { TeamConstraint } from 'utils/constraintConsts';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TeamConstraintEditor from 'components/TeamConstraintEditor';

dayjs.extend(customParseFormat);

const PARTIAL_TEAM_CONSTRAINT: RecursivePartial<TeamConstraint> = {
  name: '',
  teams: [],
  location: 'any',
  week: [],
  networks: [],
  min: 0,
  max: 0,
  hard: false,
  penalty: 'none',
};

// eslint-disable-next-line arrow-body-style
const validateConstraint = (constraint: RecursivePartial<TeamConstraint>) => {
  if (!constraint.name?.length) {
    return false;
  }
  if (!constraint.teams?.length) {
    return false;
  }
  if (!constraint.week?.length) {
    return false;
  }
  if (!constraint.networks?.length) {
    return false;
  }
  if (constraint.min === undefined) {
    return false;
  }
  if (constraint.max === undefined) {
    return false;
  }
  if (constraint.max < constraint.min) {
    return false;
  }
  if (constraint.hard === undefined) {
    return false;
  }
  if (constraint.penalty === undefined) {
    return false;
  }
  return true;
};

export function TeamConstraintsTable() {
  const { draftTeamConstraints, setDraftTeamConstraints } = useAppStore();

  const [isDraftConstraintValid, setIsDraftConstraintValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditingEntry, setCurrentEditingEntry] = useState<TeamConstraint | null>(null);
  const [draftConstraint, setDraftConstraint] =
    useState<RecursivePartial<TeamConstraint>>(PARTIAL_TEAM_CONSTRAINT);

  const [editorOpened, editorModalHandlers] = useDisclosure(false);

  useEffect(() => {
    setIsDraftConstraintValid(validateConstraint(draftConstraint));
  }, [draftConstraint]);

  const openDeleteConfirmModal = (row: MRT_Row<TeamConstraint>) => {
    modals.openConfirmModal({
      title: 'Are you sure you want to delete this constraint?',
      children: <Text>This cannot be undone.</Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        setDraftTeamConstraints(draftTeamConstraints.filter((d) => d !== row.original));
      },
    });
  };

  const handleAddConstraintClick = () => {
    // TODO(maciek): Handle removing duplicates
    setDraftTeamConstraints([draftConstraint as TeamConstraint, ...draftTeamConstraints]);
  };

  const handleUpdateConstraintClick = () => {
    const newConstraints = draftTeamConstraints.filter((d) => d !== currentEditingEntry);
    setDraftTeamConstraints([draftConstraint as TeamConstraint, ...newConstraints]);
    setCurrentEditingEntry(null);
  };

  const dynamicConstraintsColumns = useMemo<MRT_ColumnDef<TeamConstraint>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'teams',
        header: 'Teams',
        accessorFn: (row) => row.teams.join(', '),
        size: 200,
      },
      {
        accessorKey: 'location',
        header: 'Location',
        accessorFn: (row) => row.location.charAt(0).toUpperCase() + row.location.slice(1),
        size: 100,
      },
      {
        accessorKey: 'week',
        header: 'Week',
        accessorFn: (row) => row.week.join(', '),
        size: 100,
      },
      {
        accessorKey: 'networks',
        header: 'Networks',
        accessorFn: (row) => row.networks.join(', '),
        size: 200,
      },
      {
        accessorKey: 'min',
        header: 'Min',
        size: 100,
      },
      {
        accessorKey: 'max',
        header: 'Max',
        size: 100,
      },
      {
        accessorKey: 'holiday_slot',
        header: 'Holiday Slot',
        size: 200,
      },
      {
        accessorKey: 'hard',
        header: 'Hard',
        accessorFn: (row) => (row.hard ? 'Yes' : 'No'),
        size: 100,
      },
      {
        accessorKey: 'penalty',
        header: 'Penalty',
        accessorFn: (row) => row.penalty.charAt(0).toUpperCase() + row.penalty.slice(1),
        size: 140,
      },
    ],
    []
  );

  const dynamicConstraintsTable = useMantineReactTable({
    data: draftTeamConstraints,
    columns: dynamicConstraintsColumns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    enableColumnDragging: false,
    enableHiding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    layoutMode: 'grid-no-grow',
    positionToolbarAlertBanner: 'none',
    initialState: {
      // @ts-ignore
      density: '6px',
    },
    mantineTableContainerProps: ({ table }) => ({
      style: { height: table.getState().isFullScreen ? '100%' : 'calc(100vh - 335px)' },
    }),
    mantineTableProps: {
      striped: true,
    },
    renderTopToolbarCustomActions: () => (
      <Flex flex={1} h="100%" style={{ alignSelf: 'center' }}>
        <Title order={4}>Team constraints</Title>
        <Box h="100%" ml="auto" mr="xs" my="auto">
          <Group gap="sm">
            <Button variant="filled" size="xs" onClick={editorModalHandlers.open}>
              Add Team Constraint
            </Button>
            <Button
              // onClick={() => downloadCSV(events)}
              variant="default"
              size="xs"
              radius="xs"
              // leftSection={<FontAwesomeIcon icon={faCircleDown} color="gray" />}
            >
              Download CSV
            </Button>
          </Group>
        </Box>
      </Flex>
    ),
    enableStickyHeader: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enablePagination: false,
    enableRowVirtualization: true,
    renderBottomToolbar: ({ table }) => (
      <Flex
        justify="flex-end"
        bg="#F8F9FA"
        pr="lg"
        h="24px"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          fontSize: '14px',
        }}
        align="center"
      >
        {table.getRowModel().rows.length} results
      </Flex>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex gap="sm" w="100px">
        <Tooltip label="Edit">
          <ActionIcon
            variant="default"
            onClick={() => {
              setIsEditing(true);
              setCurrentEditingEntry(row.original);
              setDraftConstraint({ ...row.original });
              editorModalHandlers.open();
            }}
          >
            <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faPenToSquare} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon variant="default" onClick={() => openDeleteConfirmModal(row)}>
            <FontAwesomeIcon
              color="red"
              style={{ width: rem(12), height: rem(12) }}
              icon={faCircleMinus}
            />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return (
    <Stack>
      <Modal
        opened={editorOpened}
        size="100$"
        closeOnClickOutside={false}
        onClose={() => {
          setIsEditing(false);
          editorModalHandlers.close();
          setDraftConstraint(PARTIAL_TEAM_CONSTRAINT);
        }}
        title={
          <Text fw={700} size="lg">
            {isEditing ? 'Edit' : 'Add'} team constraint
          </Text>
        }
      >
        <TeamConstraintEditor value={draftConstraint} onChange={setDraftConstraint} />
        <Flex>
          <Button
            size="sm"
            ml="auto"
            disabled={!isDraftConstraintValid}
            onClick={() => {
              if (isEditing) {
                handleUpdateConstraintClick();
              } else {
                handleAddConstraintClick();
              }
              setDraftConstraint(PARTIAL_TEAM_CONSTRAINT);
              editorModalHandlers.close();
            }}
          >
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </Flex>
      </Modal>
      <MantineReactTable table={dynamicConstraintsTable} />
    </Stack>
  );
}
