import { useEffect, useMemo, useState } from 'react';
import { Group, Select, Stack, Text } from '@mantine/core';
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';

import { WEEK_KEYS, WeekSummaryEntry } from 'utils/scheduleConsts';
import { getViewershipDisplayValue } from 'utils/scheduleUtils';
import { useAppStore } from 'stores/appStore';
import { getRouteApi, useNavigate } from '@tanstack/react-router';

export function WeekSummary() {
  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedWeek, setSelectedWeek] = useState(routeSearch.w ? routeSearch.w.toString() : '1');
  const [data, setData] = useState<WeekSummaryEntry[]>([]);
  const currentWeekSummary = useAppStore((state) => state.currentWeekSummary);
  const navigate = useNavigate({ from: '/schedules' });

  useEffect(() => {
    if (currentWeekSummary !== null) {
      const newData = currentWeekSummary[Number(selectedWeek) as keyof typeof currentWeekSummary];
      setData(newData);
    }
    if (selectedWeek === '1') {
      navigate({ to: '/schedules', search: (prev) => ({ s: prev.s, t: 'week_summary' }) });
    } else {
      navigate({
        to: '/schedules',
        search: (prev) => ({ s: prev.s, t: 'week_summary', w: Number(selectedWeek) }),
      });
    }
  }, [currentWeekSummary, selectedWeek]);

  const columns = useMemo<MRT_ColumnDef<WeekSummaryEntry>[]>(
    () => [
      {
        id: 'slot',
        header: '',
        accessorFn: (row) => `${row.network} ${row['day.of.week']} ${row['time.of.day']}`,
        Cell: ({ renderedCellValue }) => (
          <Text
            style={() => ({
              color: 'black',
              fontWeight: 'bold',
              fontSize: 14,
              height: '40px',
              display: 'inline-block',
            })}
          >
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: 'matchup',
        header: '',
        enableColumnActions: false,
        //right align the header and body cells
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        //accessorFn: (row) => getViewershipDisplayValue(row.rating),
        accessorFn: (row) => row.rating,
        sortingFn: (a, b) => a.original.rating - b.original.rating,
        id: 'rating',
        header: '',
        enableColumnActions: false,
        //right align the header and body cells
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },

        Cell: () => '',

        aggregationFn: 'sum',
        //required to render an aggregated cell, show the average salary in the group
        AggregatedCell: ({ cell }) => (
          <Text
            span
            style={() => ({
              color: 'black',
              fontWeight: 'normal',
              fontSize: 13,
              height: '40px',
              display: 'inline-block',
            })}
          >
            {getViewershipDisplayValue(cell.getValue<number>())}
          </Text>
        ),
      },
    ],
    []
  );

  const weeksSummaryTable = useMantineReactTable({
    data,
    columns,
    enableTopToolbar: false,
    enableExpandAll: false,
    enableDensityToggle: false,
    manualExpanding: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableGrouping: true, // need this to get grouping at all
    enableColumnResizing: false,
    enableColumnDragging: false,
    positionToolbarAlertBanner: 'bottom',
    enableSorting: false, // this will hide the sorting icons ... but also does not initally sorrt
    enableColumnActions: false,
    mantineTableProps: {
      striped: false,
      withRowBorders: false,
      w: '200px',
    },
    initialState: {
      columnVisibility: {
        'mrt-row-expand': false, // hide row expand column by default
      },
      expanded: true,
      // @ts-ignore
      density: '',
      sorting: [
        { id: 'slot', desc: false },
        { id: 'matchup', desc: true },
      ],
      grouping: ['slot'],
      showColumnFilters: false,
      showColumnActions: false,
    },
  });

  return (
    <Stack gap="xs">
      <Group align="center">
        <Text>Week</Text>
        <Select
          checkIconPosition="right"
          value={selectedWeek}
          allowDeselect={false}
          onChange={(value) => setSelectedWeek(value as string)}
          data={WEEK_KEYS.filter((week) => week !== 18).map((week) => `${week}`)}
          searchable
          w="90px"
        />
      </Group>
      <MantineReactTable table={weeksSummaryTable} />
    </Stack>
  );
}
