/* eslint-disable no-restricted-syntax */
import {
  Box,
  Container,
  Flex,
  Select,
  TextInput,
  Stack,
  Divider,
  NumberInput,
  MultiSelect,
} from '@mantine/core';
import { CONSTRAINT_PENALTY, ConstraintPenalty, NetworkConstraint } from 'utils/constraintConsts';
import {
  NETWORK_KEYS_SIMPLE,
  NetworkTypeSimpleExtended,
  RecursivePartial,
  TEAM_KEYS,
  TeamType,
  WEEK_KEYS_EXTENDED,
  WeekExtendedType,
} from 'utils/scheduleConsts';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const BroadcastWindowEntry = ({
  constraint,
  onChange,
}: {
  constraint: RecursivePartial<NetworkConstraint>;
  onChange: (value: RecursivePartial<NetworkConstraint>) => void;
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <Stack>
      <Flex gap="xl">
        <TextInput
          w="240"
          description="Name"
          value={constraint.name}
          onChange={(event) => onChange({ ...constraint, name: event.currentTarget.value })}
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          // @ts-ignore
          value={constraint.network}
          onChange={(network) =>
            onChange({
              ...constraint,
              network: network as NetworkTypeSimpleExtended[],
            })
          }
          data={NETWORK_KEYS_SIMPLE.slice().sort()}
          searchable
          description="Network"
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="120"
          searchable
          // @ts-ignore
          value={constraint.team}
          onChange={(teams) =>
            onChange({
              ...constraint,
              team: teams as (TeamType | 'All')[],
            })
          }
          data={TEAM_KEYS}
          description="Teams"
          inputWrapperOrder={['input', 'description']}
        />
        <MultiSelect
          w="140"
          searchable
          // @ts-ignore
          value={constraint.week?.map((week) => week.toString())}
          onChange={(week) => {
            const newVals: WeekExtendedType[] = [];
            for (const val of week) {
              const newVal = val === 'All' ? 'All' : Number(val);
              newVals.push(newVal as WeekExtendedType);
            }
            onChange({
              ...constraint,
              week: newVals,
            });
          }}
          data={WEEK_KEYS_EXTENDED.map((week) => week.toString())}
          description="Week"
          inputWrapperOrder={['input', 'description']}
        />
      </Flex>
      <Divider />
      <Flex gap="xl">
        <NumberInput
          w="80"
          description="Min games"
          value={constraint.min}
          onChange={(e) => {
            const value = Number(e);
            if (value <= (constraint.max ?? 0) && value >= 0) {
              onChange({ ...constraint, min: value });
            }
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <NumberInput
          w="80"
          description="Max games"
          value={constraint.max}
          onChange={(e) => {
            const value = Number(e);
            if (value >= (constraint.min ?? 0) && value >= 0) {
              onChange({ ...constraint, max: value });
            }
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="120"
          withCheckIcon={false}
          description="Double header"
          inputWrapperOrder={['input', 'description']}
          allowDeselect={false}
          data={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={constraint.double_header ? 'true' : 'false'}
          onChange={(value) => onChange({ ...constraint, double_header: value === 'true' })}
        />
        <Select
          w="120"
          withCheckIcon={false}
          description="Hard"
          inputWrapperOrder={['input', 'description']}
          allowDeselect={false}
          data={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={constraint.hard ? 'true' : 'false'}
          onChange={(value) => onChange({ ...constraint, hard: value === 'true' })}
        />
        <Select
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          value={constraint.penalty}
          onChange={(penalty) =>
            onChange({
              ...constraint,
              penalty: penalty as ConstraintPenalty,
            })
          }
          data={CONSTRAINT_PENALTY.map((entry) => ({
            label: entry.charAt(0).toUpperCase() + entry.slice(1),
            value: entry,
          }))}
          description="Penalty"
          inputWrapperOrder={['input', 'description']}
        />
      </Flex>
    </Stack>
  );
};

export function NetworkConstraintEditor({
  value,
  onChange,
}: {
  value: RecursivePartial<NetworkConstraint>;
  onChange: (value: RecursivePartial<NetworkConstraint>) => void;
}) {
  return (
    <Box>
      <Container size="xl">
        <BroadcastWindowEntry constraint={value} onChange={onChange} />
      </Container>
    </Box>
  );
}
