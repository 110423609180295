import { Stack, Title, Divider, SimpleGrid, NumberInput, rem, Text } from '@mantine/core';
import { useAppStore } from 'stores/appStore';
import { PercentageThresholdsIndex } from 'utils/scheduleConsts';

import classes from './NetworkDials.module.css';

export default function NetworkDials() {
  const {
    draftPercentageThresholds,
    updateDraftPercentageThresholds,
    setDraftPercentageThresholds,
  } = useAppStore((state) => ({
    draftPercentageThresholds: state.draftPercentageThresholds,
    updateDraftPercentageThresholds: state.updateDraftPercentageThresholds,
    setDraftPercentageThresholds: state.setDraftPercentageThresholds,
  }));

  if (Object.keys(draftPercentageThresholds).length === 0) {
    setDraftPercentageThresholds({
      Amz: 0,
      ESPN: -10,
      'CBS DH': 0,
      'CBS Regional': 0,
      'CBS Single': 0,
      NFLN: 0,
      NBC: 0,
      'Fox DH': 0,
      'Fox Regional': 0,
      'Fox Single': 0,
    });
  }
  return (
    <Stack gap={6} style={{ width: 'fit-content' }}>
      <Title order={4}>Minimum Percentage Change</Title>
      <Text size="xs">Set a minimum percentage change from last year, by individual networks.</Text>
      <Divider />
      <SimpleGrid cols={5} py="sm" spacing={0} verticalSpacing="sm" w={1000}>
        {Object.keys(draftPercentageThresholds).map((key) => (
          <NumberInput
            key={key}
            classNames={{
              root: classes.percentageThresholdInputWrapper,
            }}
            clampBehavior="strict"
            size="xs"
            label={key}
            onChange={(value) =>
              updateDraftPercentageThresholds(key as keyof PercentageThresholdsIndex, Number(value))
            }
            value={draftPercentageThresholds[key as keyof PercentageThresholdsIndex]}
            style={{ width: rem(160) }}
            min={-10}
            max={6}
            onBlur={(e) => {
              // Set empty string to 0 on blur.
              if (e.currentTarget.value === '') {
                updateDraftPercentageThresholds(key as keyof PercentageThresholdsIndex, 0);
                setTimeout(
                  // eslint-disable-next-line func-names
                  function () {
                    // @ts-ignore
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.value = '0%';
                  }.bind(e.currentTarget),
                  0
                );
              }
            }}
            suffix="%"
            radius="sm"
          />
        ))}
      </SimpleGrid>
    </Stack>
  );
}
