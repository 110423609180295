import { useEffect, useMemo, useState } from 'react';
import { Title, Text } from '@mantine/core';
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'clsx';

import {
  NetworkType,
  ViewershipPredictionsIndex,
  ViewershipPredictionsRowEntry,
} from 'utils/scheduleConsts';
import { getViewershipDisplayValue } from 'utils/scheduleUtils';
import { useAppStore } from 'stores/appStore';

import classes from './ViewershipPredictions.module.css';

const columnHelper = createColumnHelper<ViewershipPredictionsRowEntry>();

const matchupCellGenerator = (
  // Note: Any network type will work for this def.
  info: CellContext<ViewershipPredictionsRowEntry, ViewershipPredictionsRowEntry['NBC']>
) => (
  <>
    {info.getValue().map((entry: any) => (
      <p className={entry.highlight ? classes.highlight : ''} key={entry.matchup}>
        {/* Replace '-' with non-breaking hyphen. */}
        {entry.matchup.replaceAll(/\s/g, '\u000A').replaceAll('-', '‑')}
      </p>
    ))}
  </>
);

const ratingCellGenerator = (
  // Note: Any network type will work for this def.
  info: CellContext<ViewershipPredictionsRowEntry, ViewershipPredictionsRowEntry['NBC']>
) => (
  <>
    {info.getValue().map((entry) => (
      <p key={entry.matchup} className={entry.highlight ? classes.highlight : ''}>
        {getViewershipDisplayValue(entry.viewers)}
      </p>
    ))}
  </>
);

export function ViewershipPredictions() {
  const [data, setData] = useState<ViewershipPredictionsIndex>();
  const currentViewershipPredictions = useAppStore((state) => state.currentViewershipPredictions);

  const getAvg = (network: NetworkType, year: number) => {
    let temp = 0;
    if (year === 2023) {
      temp = data?.averages.find((entry) => entry.network === network)?.[2023] || 0;
    } else if (year === 2024) {
      temp = data?.averages.find((entry) => entry.network === network)?.[2024] || 0;
    }
    return getViewershipDisplayValue(temp);
  };

  // Gets totals by network type. Saving for later.
  // const getTotal = (network: NetworkType) => {
  //   const total = data?.viewership.reduce(
  //     (acc, cur) => acc + cur[network].reduce((acc2, cur2) => acc2 + cur2.viewers, 0),
  //     0
  //   );
  //   return getViewershipDisplayValue(total || 0);
  // };

  const columns = [
    columnHelper.accessor('week', {
      cell: (info) => info.getValue(),
      header: undefined,
    }),
    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.nbc])}
        >
          NBC
        </Title>
      ),
      id: 'NBC',
      columns: [
        columnHelper.accessor('NBC', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('NBC', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('NBC', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('NBC', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'NBC_total',
        }),
      ],
    }),
    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.amz])}
        >
          Amazon
        </Title>
      ),
      id: 'Amz',
      columns: [
        columnHelper.accessor('Amz', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('Amz', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('Amz', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('Amz', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'Amz_total',
        }),
      ],
    }),
    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.espn])}
        >
          ESPN
        </Title>
      ),
      id: 'ESPN',
      columns: [
        columnHelper.accessor('ESPN', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('ESPN', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('ESPN', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('ESPN', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'ESPN_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.foxdh])}
        >
          Fox DH
        </Title>
      ),
      id: 'Fox DH',
      columns: [
        columnHelper.accessor('Fox DH', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('Fox DH', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('Fox DH', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('Fox DH', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'Fox_dh_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.foxsingle])}
        >
          Fox Single
        </Title>
      ),
      id: 'Fox Single',
      columns: [
        columnHelper.accessor('Fox Single', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('Fox Single', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('Fox Single', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('Fox Single', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'Fox_single_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.foxregional])}
        >
          Fox Regional
        </Title>
      ),
      id: 'Fox Regional',
      columns: [
        columnHelper.accessor('Fox Regional', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('Fox Regional', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('Fox Regional', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('Fox Regional', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'Fox_regional_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.cbsdh])}
        >
          CBS DH
        </Title>
      ),
      id: 'CBS DH',
      columns: [
        columnHelper.accessor('CBS DH', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('CBS DH', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('CBS DH', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('CBS DH', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'CBS_dh_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.cbssingle])}
        >
          CBS Single
        </Title>
      ),
      id: 'CBS Single',
      columns: [
        columnHelper.accessor('CBS Single', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('CBS Single', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('CBS Single', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('CBS Single', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'CBS_single_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.cbsregional])}
        >
          CBS Regional
        </Title>
      ),
      id: 'CBS Regional',
      columns: [
        columnHelper.accessor('CBS Regional', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('CBS Regional', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('CBS Regional', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('CBS Regional', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'CBS_regional_total',
        }),
      ],
    }),

    columnHelper.group({
      header: () => (
        <Title
          style={() => ({
            fontSize: 16,
            height: '40px',
          })}
          order={4}
          p="xs"
          className={cx([classes.networkCell, classes.nfln])}
        >
          NFLN
        </Title>
      ),
      id: 'NFLN',
      columns: [
        columnHelper.accessor('NFLN', {
          cell: (info) => matchupCellGenerator(info),
          header: () => (
            <Text fw={500} lh={1.6} fz={13} p={0}>
              {`2023 ${getAvg('NFLN', 2023)}`.replaceAll(/\s/g, '\u000A')}
            </Text>
          ),
        }),
        columnHelper.accessor('NFLN', {
          cell: (info) => ratingCellGenerator(info),
          header: () => (
            <>
              <Text fw={500} lh={1.6} fz={13} p={0}>
                {`2024 ${getAvg('NFLN', 2024)}`.replaceAll(/\s/g, '\u000A')}
              </Text>
            </>
          ),
          id: 'NFLN_total',
        }),
      ],
    }),
  ];

  useEffect(() => {
    if (currentViewershipPredictions) {
      setData(currentViewershipPredictions);
    }
  }, [currentViewershipPredictions]);
  const table = useReactTable<ViewershipPredictionsRowEntry>({
    data: data?.viewership || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const rowModel = useMemo(() => table.getRowModel(), [table, data]);

  return (
    <div className={classes.viewershipPredictionsContainer}>
      <table style={{ fontSize: 13 }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rowModel.rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
