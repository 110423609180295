import { useMemo, useState } from 'react';
import {
  Group,
  Button,
  Flex,
  Title,
  Box,
  ActionIcon,
  rem,
  Tooltip,
  Text,
  Modal,
  Stack,
} from '@mantine/core';
import { useMantineReactTable, MantineReactTable, MRT_ColumnDef } from 'mantine-react-table';

import { useAppStore } from 'stores/appStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/sharp-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { CoreConstraintEntry } from 'utils/constraintConsts';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CoreConstraintEditor from 'components/CoreConstraintEditor';

dayjs.extend(customParseFormat);

export function CoreConstraintsTable() {
  const { draftCoreConstraints, setDraftCoreConstraints } = useAppStore();

  const [currentEditingEntry, setCurrentEditingEntry] = useState<CoreConstraintEntry | null>(null);
  const [draftConstraint, setDraftConstraint] = useState<CoreConstraintEntry | null>(null);

  const [editorOpened, editorModalHandlers] = useDisclosure(false);

  const handleUpdateConstraintClick = () => {
    const newConstraints = draftCoreConstraints.filter((d) => d !== currentEditingEntry);
    setDraftCoreConstraints([draftConstraint!, ...newConstraints]);
    setCurrentEditingEntry(null);
  };

  const dynamicConstraintsColumns = useMemo<MRT_ColumnDef<CoreConstraintEntry>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 500,
      },
      {
        accessorFn: (row) => row.category.charAt(0).toUpperCase() + row.category.slice(1),
        header: 'Category',
        size: 120,
      },
      {
        accessorFn: (row) => (row.hard ? 'Yes' : 'No'),
        header: 'Hard',
        size: 80,
      },
      {
        accessorKey: 'max',
        header: 'Max',
        size: 80,
      },
      {
        accessorFn: (row) => row.penalty.charAt(0).toUpperCase() + row.penalty.slice(1),
        header: 'Penalty',
        size: 100,
      },
      {
        accessorFn: (row) => row.team_exceptions.join(', '),
        header: 'Team Exceptions',
        size: 160,
      },
      {
        accessorFn: (row) => row.matchup_restrictions.map((r) => `${r.away}-${r.home}`).join(', '),
        header: 'Matchup Restrictions',
        size: 200,
      },
    ],
    []
  );

  const dynamicConstraintsTable = useMantineReactTable({
    data: draftCoreConstraints,
    columns: dynamicConstraintsColumns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    enableColumnDragging: false,
    enableHiding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    layoutMode: 'grid-no-grow',
    positionToolbarAlertBanner: 'none',
    initialState: {
      // @ts-ignore
      density: '6px',
    },
    mantineTableContainerProps: ({ table }) => ({
      style: { height: table.getState().isFullScreen ? '100%' : 'calc(100vh - 335px)' },
    }),
    mantineTableProps: {
      striped: true,
    },
    renderTopToolbarCustomActions: () => (
      <Flex flex={1} h="100%" style={{ alignSelf: 'center' }}>
        <Title order={4}>Core Constraints</Title>
        <Box h="100%" ml="auto" mr="xs" my="auto">
          <Group gap="sm">
            <Button
              // onClick={() => downloadCSV(events)}
              variant="default"
              size="xs"
              radius="xs"
              // leftSection={<FontAwesomeIcon icon={faCircleDown} color="gray" />}
            >
              Download CSV
            </Button>
          </Group>
        </Box>
      </Flex>
    ),
    enableStickyHeader: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enablePagination: false,
    enableRowVirtualization: true,
    renderBottomToolbar: ({ table }) => (
      <Flex
        justify="flex-end"
        bg="#F8F9FA"
        pr="lg"
        h="24px"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          fontSize: '14px',
        }}
        align="center"
      >
        {table.getRowModel().rows.length} results
      </Flex>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex gap="sm" w="100px">
        <Tooltip label="Edit">
          <ActionIcon
            variant="default"
            onClick={() => {
              setCurrentEditingEntry(row.original);
              setDraftConstraint({ ...row.original });
              editorModalHandlers.open();
            }}
          >
            <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faPenToSquare} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return (
    <Stack>
      <Modal
        opened={editorOpened}
        size="100$"
        closeOnClickOutside={false}
        onClose={() => {
          editorModalHandlers.close();
          setDraftConstraint(null);
        }}
        title={
          <Text fw={700} size="lg">
            Edit core constraint
          </Text>
        }
      >
        <CoreConstraintEditor value={draftConstraint} onChange={setDraftConstraint} />
        <Flex>
          <Button
            size="sm"
            ml="auto"
            onClick={() => {
              handleUpdateConstraintClick();
              setDraftConstraint(null);
              editorModalHandlers.close();
            }}
          >
            Update
          </Button>
        </Flex>
      </Modal>
      <MantineReactTable table={dynamicConstraintsTable} />
    </Stack>
  );
}
