import { useMemo } from 'react';
import { Button, rem } from '@mantine/core';

import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';

import { ConstraintEntry, ConstraintTypeTranslations } from 'utils/scheduleConsts';
import { getStringifiedConstraint } from 'utils/scheduleUtils';
import { useAppStore } from 'stores/appStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToBracket } from '@fortawesome/sharp-regular-svg-icons';

export function ConstraintsViewer() {
  const [currentConstraints] = useAppStore((state) => [state.currentConstraints]);
  const [currentScheduleName] = useAppStore((state) => [state.currentScheduleName]);

  const columns = useMemo<MRT_ColumnDef<ConstraintEntry>[]>(
    () => [
      {
        accessorFn: (row) => `${ConstraintTypeTranslations[row.type]}`,
        id: 'type',
        header: 'Constraint Type',
      },
      {
        accessorFn: (row) => `${getStringifiedConstraint(row)}`,
        id: 'definition',
        header: 'Definition',
      },
    ],
    []
  );

  const handleExportRows = (rows: MRT_Row<ConstraintEntry>[]) => {
    const rowData = rows.map((row) => {
      const dataArr = row.getAllCells().map((cell) => cell.getValue());
      return {
        'Constraint Type': dataArr[0],
        Definition: dataArr[1],
      };
    });

    const csvConfig = mkConfig({
      filename: `${currentScheduleName.replace(/[^A-Z0-9]/gi, '_')}_Constraints`,
      title: `${currentScheduleName} Constraints`,
      fieldSeparator: ',',
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const constraintsTable = useMantineReactTable({
    data: currentConstraints,
    columns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    // enableStickyHeader: true,
    initialState: { density: 'xs', pagination: { pageSize: 30, pageIndex: 0 } },
    paginationDisplayMode: 'pages',
    mantineTableProps: {
      striped: true,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          handleExportRows(table.getPrePaginationRowModel().rows);
        }}
        leftSection={
          <FontAwesomeIcon
            style={{ width: rem(12), height: rem(12) }}
            icon={faArrowDownToBracket}
          />
        }
        variant="default"
        size="xs"
      >
        Download CSV
      </Button>
    ),
  });

  return <MantineReactTable table={constraintsTable} />;
}
