import { useEffect, useMemo, useState } from 'react';
import {
  Group,
  Button,
  Flex,
  Title,
  Box,
  ActionIcon,
  rem,
  Tooltip,
  Text,
  Modal,
  Stack,
} from '@mantine/core';
import {
  useMantineReactTable,
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
} from 'mantine-react-table';

import {
  ConstraintEntry,
  ConstraintType,
  ConstraintTypeTranslations,
  RecursivePartial,
} from 'utils/scheduleConsts';
import { getStringifiedConstraint, removeDuplicateConstraints } from 'utils/scheduleUtils';
import { useAppStore } from 'stores/appStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faCircleMinus } from '@fortawesome/sharp-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import ConstraintEditor from 'components/ConstraintEditor';

const validateConstraint = (constraint: RecursivePartial<ConstraintEntry>) => {
  if (constraint.type === ConstraintType.STADIUM_BLOCK) {
    return Boolean(
      constraint.params?.team && constraint.params?.week && constraint.params?.slot?.length
    );
  }
  if (constraint.type === ConstraintType.MATCHUP_GUARANTEE) {
    return Boolean(
      constraint.params?.matchup &&
        constraint.params?.week?.length &&
        constraint.params?.network?.length &&
        (constraint.params?.inclusive === true || constraint.params?.inclusive === false)
    );
  }
  if (constraint.type === ConstraintType.TEAM_GUARANTEE) {
    return Boolean(
      constraint.params?.team?.length &&
        constraint.params?.week?.length &&
        constraint.params?.network?.length &&
        constraint.params?.min_appearances !== undefined &&
        constraint.params?.max_appearances !== undefined &&
        constraint.params.max_appearances >= constraint.params.min_appearances
    );
  }
  if (constraint.type === ConstraintType.BYE_GUARANTEE) {
    return Boolean(
      constraint.params?.team &&
        constraint.params?.week?.length &&
        (constraint.params?.inclusive === true || constraint.params?.inclusive === false)
    );
  }
  if (constraint.type === ConstraintType.HOME_AWAY_GUARANTEE) {
    return false;
    // return Boolean(
    //   constraint.params?.team?.length &&
    //     constraint.params?.home_away !== undefined &&
    //     constraint.params?.week?.length &&
    //     constraint.params?.network?.length &&
    //     constraint.params?.min_appearances !== undefined &&
    //     constraint.params?.max_appearances !== undefined &&
    //     constraint.params.max_appearances >= constraint.params.min_appearances
    // );
  }
  if (constraint.type === ConstraintType.PRIMETIME_MIN_MAX) {
    return Boolean(
      constraint.params?.team?.length &&
        constraint.params?.network?.length &&
        constraint.params?.min_appearances !== undefined &&
        constraint.params?.max_appearances !== undefined &&
        constraint.params.max_appearances >= constraint.params.min_appearances
    );
  }
  return false;
};

export function DynamicConstraintsTable() {
  const { draftConstraints, setDraftConstraints } = useAppStore();

  const [isDraftConstraintValid, setIsDraftConstraintValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditingEntry, setCurrentEditingEntry] = useState<ConstraintEntry | null>(null);
  const [draftConstraint, setDraftConstraint] = useState<RecursivePartial<ConstraintEntry>>({
    type: ConstraintType.STADIUM_BLOCK,
  });

  const [editorOpened, editorModalHandlers] = useDisclosure(false);

  useEffect(() => {
    setIsDraftConstraintValid(validateConstraint(draftConstraint));
  }, [draftConstraint]);

  const openDeleteConfirmModal = (row: MRT_Row<ConstraintEntry>) => {
    modals.openConfirmModal({
      title: 'Are you sure you want to delete this constraint?',
      children: <Text>This cannot be undone.</Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        setDraftConstraints(draftConstraints.filter((d) => d !== row.original));
      },
    });
  };

  const handleAddConstraintClick = () => {
    setDraftConstraints(
      removeDuplicateConstraints([draftConstraint as ConstraintEntry, ...draftConstraints])
    );
  };

  const handleUpdateConstraintClick = () => {
    const newConstraints = draftConstraints.filter((d) => d !== currentEditingEntry);
    setDraftConstraints(
      removeDuplicateConstraints([draftConstraint as ConstraintEntry, ...newConstraints])
    );
    setCurrentEditingEntry(null);
  };

  const dynamicConstraintsColumns = useMemo<MRT_ColumnDef<ConstraintEntry>[]>(
    () => [
      {
        accessorFn: (row) => `${ConstraintTypeTranslations[row.type]}`,
        id: 'type',
        header: 'Constraint Type',
        size: 20,
      },
      {
        accessorFn: (row) => `${getStringifiedConstraint(row)}`,
        id: 'definition',
        header: 'Definition',
      },
    ],
    []
  );

  const dynamicConstraintsTable = useMantineReactTable({
    data: draftConstraints,
    columns: dynamicConstraintsColumns,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    enableColumnDragging: false,
    enableHiding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    positionToolbarAlertBanner: 'none',
    initialState: {
      // @ts-ignore
      density: '6px',
    },
    mantineTableContainerProps: ({ table }) => ({
      style: { height: table.getState().isFullScreen ? '100%' : 'calc(100vh - 335px)' },
    }),
    mantineTableProps: {
      striped: true,
    },
    renderTopToolbarCustomActions: () => (
      <Flex flex={1} h="100%" style={{ alignSelf: 'center' }}>
        <Title order={4}>Dynamic Constraints</Title>
        <Box h="100%" ml="auto" mr="xs" my="auto">
          <Group gap="sm">
            <Button variant="filled" size="xs" onClick={editorModalHandlers.open}>
              Add Constraint
            </Button>
            <Button
              // onClick={() => downloadCSV(events)}
              variant="default"
              size="xs"
              radius="xs"
              // leftSection={<FontAwesomeIcon icon={faCircleDown} color="gray" />}
            >
              Download CSV
            </Button>
          </Group>
        </Box>
      </Flex>
    ),
    enableStickyHeader: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enablePagination: false,
    enableRowVirtualization: true,
    renderBottomToolbar: ({ table }) => (
      <Flex
        justify="flex-end"
        bg="#F8F9FA"
        pr="lg"
        h="24px"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          fontSize: '14px',
        }}
        align="center"
      >
        {table.getRowModel().rows.length} results
      </Flex>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex gap="sm" w="100px">
        <Tooltip label="Edit">
          <ActionIcon
            variant="default"
            onClick={() => {
              setIsEditing(true);
              setCurrentEditingEntry(row.original);
              setDraftConstraint({ ...row.original });
              editorModalHandlers.open();
            }}
          >
            <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faPenToSquare} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon variant="default" onClick={() => openDeleteConfirmModal(row)}>
            <FontAwesomeIcon
              color="red"
              style={{ width: rem(12), height: rem(12) }}
              icon={faCircleMinus}
            />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return (
    <Stack>
      <Modal
        opened={editorOpened}
        size={1000}
        closeOnClickOutside={false}
        onClose={() => {
          setIsEditing(false);
          editorModalHandlers.close();
          setDraftConstraint({
            type: ConstraintType.STADIUM_BLOCK,
          });
        }}
        title={
          <Text fw={700} size="lg">
            {isEditing ? 'Edit' : 'Add'} constraint
          </Text>
        }
      >
        <ConstraintEditor value={draftConstraint} onChange={setDraftConstraint} />
        <Flex>
          <Button
            size="sm"
            ml="auto"
            disabled={!isDraftConstraintValid}
            onClick={() => {
              if (isEditing) {
                handleUpdateConstraintClick();
              } else {
                handleAddConstraintClick();
              }
              setDraftConstraint({
                type: ConstraintType.STADIUM_BLOCK,
              });
              editorModalHandlers.close();
            }}
          >
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </Flex>
      </Modal>
      <MantineReactTable table={dynamicConstraintsTable} />
    </Stack>
  );
}
