import { Group, Stack, Table, TableData, Title } from '@mantine/core';

import { getViewershipDisplayValue } from 'utils/scheduleUtils';
import { useAppStore } from 'stores/appStore';
import { NETWORK_KEYS, SummaryHighlightsInfo } from 'utils/scheduleConsts';

function ViewershipTable({ data }: { data: SummaryHighlightsInfo }) {
  const ratingsData = data.Ratings;
  const growthData = data.Growth;
  const rows = [];
  rows.push([
    'Overall',
    getViewershipDisplayValue(ratingsData.Total),
    `${growthData.Total > 0 ? '+' : ''}${growthData.Total.toLocaleString('en', { style: 'percent', minimumFractionDigits: 1 })}`,
  ]);
  // Do not show the 'All' network in the summary.
  NETWORK_KEYS.forEach((key) => {
    rows.push([
      key,
      // Check in place for missing Peacock entries.
      ratingsData[key] ? getViewershipDisplayValue(ratingsData[key]) : '-',
      growthData[key]
        ? `${growthData[key] > 0 ? '+' : ''}${growthData[key].toLocaleString('en', { style: 'percent', minimumFractionDigits: 1 })}`
        : '-',
    ]);
  });
  const tableData: TableData = {
    body: rows,
    head: ['Network', 'Viewership', 'Growth'],
  };
  return (
    <Stack gap={4} p="lg">
      <Title order={5}>Viewership</Title>
      <Table
        style={() => ({
          fontSize: 12,
        })}
        w="200px"
        data={tableData}
        striped
        highlightOnHover
        withRowBorders={false}
        withColumnBorders
      />
    </Stack>
  );
}

function TeamRestTable({ teamRestData }: { teamRestData: SummaryHighlightsInfo['Team Rest'] }) {
  const rows = [
    ['3A', teamRestData['3A'].join(', ')],
    ['Away after MNF Away', teamRestData['Away after MNF Away']],
    ['2A to start', teamRestData['2A to start']],
    ['2A to finish', teamRestData['2A to finish']],
    ['Rest Disparity < -15', teamRestData['Rest Disparity < -15'] || '-'],
    ['Division Series in First Half', teamRestData['Division Series in First Half'] || '-'],
  ];
  const tableData: TableData = {
    body: rows,
  };
  return (
    <Stack gap={4}>
      <Title order={5}>Team Rest</Title>
      <Table
        style={() => ({
          fontSize: 12,
        })}
        w="500px"
        data={tableData}
        striped
        highlightOnHover
        withRowBorders={false}
        withColumnBorders
      />
    </Stack>
  );
}

function KeyGamesTable({ keyGamesData }: { keyGamesData: SummaryHighlightsInfo['Key Games'] }) {
  const rows = [
    ['Season Opener', keyGamesData['Season Opener']],
    ['International', (keyGamesData['International Games'] || []).join(', ').replaceAll('-', '‑')],
    ['Thanksgiving', (keyGamesData['Thanksgiving Games'] || []).join(', ').replaceAll('-', '‑')],
    ['Black Friday', (keyGamesData['Black Friday'] || []).join(', ').replaceAll('-', '‑')],
    [
      'Saturday before Christmas',
      (keyGamesData['Saturday before Christmas'] || []).join(', ').replaceAll('-', '‑'),
    ],
    ['Christmas', (keyGamesData.Christmas || []).join(', ').replaceAll('-', '‑')],
  ];
  const tableData: TableData = {
    body: rows,
  };
  return (
    <Stack gap={4}>
      <Title order={5}>Key Games</Title>
      <Table
        style={() => ({
          fontSize: 12,
        })}
        w="400px"
        data={tableData}
        striped
        highlightOnHover
        withRowBorders={false}
        withColumnBorders
      />
    </Stack>
  );
}

export function SummaryHighlights() {
  const currentSummaryHighlights = useAppStore((state) => state.currentSummaryHighlights);

  return currentSummaryHighlights ? (
    <Group gap="xs" wrap="nowrap" align="top">
      <ViewershipTable data={currentSummaryHighlights} />
      <Stack gap="lg" p="lg">
        <KeyGamesTable keyGamesData={currentSummaryHighlights['Key Games']} />
      </Stack>
      <Stack gap="lg" p="lg">
        <TeamRestTable teamRestData={currentSummaryHighlights['Team Rest']} />
      </Stack>
    </Group>
  ) : null;
}
