/* eslint-disable no-restricted-syntax */
import {
  BroadcastWindowConstraint,
  MatchupConstraint,
  NetworkConstraint,
  StadiumBlockConstraint,
  TeamConstraint,
} from './constraintConsts';
import {
  ByeGuarantee,
  ConstraintEntry,
  ConstraintType,
  HomeAwayGuarantee,
  MatchupGuarantee,
  NetworkTypeSimpleExtended,
  PrimetimeMinMax,
  StadiumBlock,
  TeamGuarantee,
  TeamType,
  WeekExtendedType,
} from './scheduleConsts';

export const removeDuplicateBroadcastWindowConstraints = (
  constraints: BroadcastWindowConstraint[]
) => {
  const constraintMap = new Map<string, BroadcastWindowConstraint>();
  constraints.forEach((constraint) => {
    const key = JSON.stringify(constraint); // Using JSON.stringify to create a unique key for each constraint
    if (!constraintMap.has(key)) {
      constraintMap.set(key, constraint);
    }
  });
  return Array.from(constraintMap.values());
};

export const upgradeDynamicConstraints = (constraints: ConstraintEntry[]) => {
  const stadiumBlockConstraints: StadiumBlockConstraint[] = [];
  const matchupConstraints: MatchupConstraint[] = [];
  const teamConstraints: TeamConstraint[] = [];
  const networkConstraints: NetworkConstraint[] = [];

  for (const constraint of constraints) {
    if (constraint.type === ConstraintType.STADIUM_BLOCK) {
      const stadiumBlockConstraint = constraint as StadiumBlock;
      stadiumBlockConstraints.push({
        week: stadiumBlockConstraint.params.week,
        team: stadiumBlockConstraint.params.team,
        slot: stadiumBlockConstraint.params.slot,
      });
    } else if (constraint.type === ConstraintType.MATCHUP_GUARANTEE) {
      const matchupGuaranteeConstraint = constraint as MatchupGuarantee;
      const [away, home] = matchupGuaranteeConstraint.params.matchup.split('-') as TeamType[];
      matchupConstraints.push({
        name: 'Imported',
        matchup: { away: away as TeamType, home: home as TeamType },
        week: matchupGuaranteeConstraint.params.week,
        network: matchupGuaranteeConstraint.params.network,
        inclusive: matchupGuaranteeConstraint.params.inclusive,
        hard: true,
        penalty: 'high',
      });
    } else if (constraint.type === ConstraintType.TEAM_GUARANTEE) {
      const teamGuaranteeConstraint = constraint as TeamGuarantee;
      teamConstraints.push({
        name: 'Imported Team Guarantee',
        teams: teamGuaranteeConstraint.params.team,
        location: 'any',
        week: teamGuaranteeConstraint.params.week,
        networks: teamGuaranteeConstraint.params.network,
        min: teamGuaranteeConstraint.params.min_appearances,
        max: teamGuaranteeConstraint.params.max_appearances,
        holiday_slot: undefined,
        hard: true,
        penalty: 'high',
      });
    } else if (constraint.type === ConstraintType.HOME_AWAY_GUARANTEE) {
      const homeAwayGuaranteeConstraint = constraint as HomeAwayGuarantee;
      teamConstraints.push({
        name: 'Imported Home/Away Guarantee',
        teams: homeAwayGuaranteeConstraint.params.team,
        location: homeAwayGuaranteeConstraint.params.home_away,
        week: homeAwayGuaranteeConstraint.params.week,
        networks: homeAwayGuaranteeConstraint.params.network,
        min: homeAwayGuaranteeConstraint.params.min_appearances,
        max: homeAwayGuaranteeConstraint.params.max_appearances,
        holiday_slot: undefined,
        hard: true,
        penalty: 'high',
      });
    } else if (constraint.type === ConstraintType.PRIMETIME_MIN_MAX) {
      const primetimeMinMaxConstraint = constraint as PrimetimeMinMax;
      networkConstraints.push({
        name: 'Imported Primetime Min/Max',
        network: primetimeMinMaxConstraint.params.network as NetworkTypeSimpleExtended[],
        team: primetimeMinMaxConstraint.params.team,
        week: ['All'],
        time_window: '',
        min: primetimeMinMaxConstraint.params.min_appearances,
        max: primetimeMinMaxConstraint.params.max_appearances,
        double_header: false,
        hard: true,
        penalty: 'high',
      });
    } else if (constraint.type === ConstraintType.BYE_GUARANTEE) {
      const byeGuaranteeConstraint = constraint as ByeGuarantee;
      networkConstraints.push({
        name: 'Imported Bye Guarantee',
        network: ['All'],
        team: [byeGuaranteeConstraint.params.team],
        week: byeGuaranteeConstraint.params.week as WeekExtendedType[],
        time_window: '',
        min: 0,
        max: 0,
        double_header: false,
        hard: true,
        penalty: 'high',
      });
    }
  }

  return {
    stadiumBlockConstraints,
    matchupConstraints,
    teamConstraints,
    networkConstraints,
  };
};
