import { useEffect, useState } from 'react';
import { Group, MultiSelect, Stack, Table, TableData, Text, Title, rem } from '@mantine/core';
import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { useAppStore } from 'stores/appStore';
import { getHighlightsFromSchedule } from 'api/api';

import { getViewershipDisplayValue } from 'utils/scheduleUtils';
import { NETWORK_KEYS, SummaryHighlightsInfo } from 'utils/scheduleConsts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/sharp-solid-svg-icons';
import classes from './ScheduleCompare.module.css';

export function CompareTable({
  allScheduleHighlights,
  selectedIds,
}: {
  allScheduleHighlights: SummaryHighlightsInfo[];
  selectedIds: string[];
}) {
  const schedulesList = useAppStore((state) => state.schedulesList);

  const maxQualityScore = Math.max(
    ...allScheduleHighlights.map((info) => info['Quality Score'] ?? 0)
  );
  const allScheduleHighlightsSorted = allScheduleHighlights
    .map((entry, origIndex) => ({
      ...entry,
      origIndex,
    }))
    .sort((a, b) => (b['Quality Score'] ?? 0) - (a['Quality Score'] ?? 0));

  let bestQualityScoreIdx = -1;
  if (maxQualityScore !== 0) {
    bestQualityScoreIdx = allScheduleHighlightsSorted.findIndex(
      (info) => info['Quality Score'] === maxQualityScore
    );
  }

  const networkRows = NETWORK_KEYS.map((key) => {
    const maxVal = Math.max(...allScheduleHighlightsSorted.map((info) => info.Ratings[key]));
    return [
      key,
      ...allScheduleHighlightsSorted.map((info) =>
        // Check in place for missing peacock entries.
        info.Ratings[key] ? (
          <Text lh="lg" size="14px" td={info.Ratings[key] === maxVal ? 'underline' : ''}>
            {getViewershipDisplayValue(info.Ratings[key])}{' '}
            {/* Check in place for missing Peacock entries. */}
            {info.Growth[key]
              ? `(${info.Growth[key] > 0 ? '+' : ''}${info.Growth[key].toLocaleString('en', {
                  style: 'percent',
                  minimumFractionDigits: 1,
                })})`
              : ''}
          </Text>
        ) : (
          '-'
        )
      ),
    ];
  });

  const head = [
    '',
    ...allScheduleHighlightsSorted.map((info, idx) => (
      <Group gap="xs" className={idx === bestQualityScoreIdx ? classes.highestRank : ''}>
        <Title order={6} style={{ textWrap: 'balance' }}>
          {idx === bestQualityScoreIdx && (
            <FontAwesomeIcon
              style={{ width: rem(14), height: rem(14), paddingRight: '8px' }}
              icon={faBadgeCheck}
            />
          )}
          {schedulesList.find((x) => x.id === selectedIds[info.origIndex])?.name}
        </Title>
      </Group>
    )),
  ];

  const maxTotal = Math.max(...allScheduleHighlightsSorted.map((info) => info.Ratings.Total));
  const rows = [
    [
      'Overall',
      ...allScheduleHighlightsSorted.map((info) => (
        <Text td={info.Ratings.Total === maxTotal ? 'underline' : ''}>
          {getViewershipDisplayValue(info.Ratings.Total)} ({info.Growth.Total > 0 ? '+' : ''}
          {info.Growth.Total.toLocaleString('en', { style: 'percent', minimumFractionDigits: 1 })})
        </Text>
      )),
    ],
    ...networkRows,
    // Replace '-' with non-breaking hyphen.
    [
      'Season Opener',
      ...allScheduleHighlightsSorted.map((info) =>
        info['Key Games']['Season Opener'].replaceAll('-', '‑')
      ),
    ],
    [
      'International',
      ...allScheduleHighlightsSorted.map((info) =>
        info['Key Games']['International Games'].map((val) => val.replaceAll('-', '‑')).join(', ')
      ),
    ],
    [
      'Thanksgiving',
      ...allScheduleHighlightsSorted.map((info) =>
        info['Key Games']['Thanksgiving Games'].map((val) => val.replaceAll('-', '‑')).join(', ')
      ),
    ],
    [
      'Black Friday',
      ...allScheduleHighlightsSorted.map((info) =>
        info['Key Games']['Black Friday'].map((val) => val.replaceAll('-', '‑')).join(', ')
      ),
    ],
    [
      'Saturday before Christmas',
      ...allScheduleHighlightsSorted.map((info) =>
        info['Key Games']['Saturday before Christmas']
          .map((val) => val.replaceAll('-', '‑'))
          .join(', ')
      ),
    ],
    [
      'Christmas',
      ...allScheduleHighlightsSorted.map((info) =>
        info['Key Games'].Christmas.map((val) => val.replaceAll('-', '‑')).join(', ')
      ),
    ],
    ['3A', ...allScheduleHighlightsSorted.map((info) => info['Team Rest']['3A'].join(', '))],
    [
      'Away after MNF Away',
      ...allScheduleHighlightsSorted.map((info) => info['Team Rest']['Away after MNF Away']),
    ],
    ['2A to start', ...allScheduleHighlightsSorted.map((info) => info['Team Rest']['2A to start'])],
    [
      '2A to finish',
      ...allScheduleHighlightsSorted.map((info) => info['Team Rest']['2A to finish']),
    ],
    [
      'Rest Disparity < -15',
      ...allScheduleHighlightsSorted.map(
        (info) => info['Team Rest']['Rest Disparity < -15'] || '-'
      ),
    ],
    [
      'Division Series in First Half',
      ...allScheduleHighlightsSorted.map(
        (info) => info['Team Rest']['Division Series in First Half'] || '-'
      ),
    ],
  ];
  const tableData: TableData = {
    head,
    body: rows,
  };
  return (
    <Stack gap="md">
      <Table
        horizontalSpacing="sm"
        data={tableData}
        striped
        highlightOnHover
        withRowBorders={false}
        withColumnBorders
      />
    </Stack>
  );
}

export function ScheduleCompare() {
  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const navigate = useNavigate({ from: '/schedules' });

  const [data, setData] = useState<SummaryHighlightsInfo[]>([]);
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);
  const schedulesList = useAppStore((state) => state.schedulesList);

  const getSelectedSchedules = () => {
    const scheduleIds = routeSearch.c
      ? routeSearch.c.filter((x) => schedulesList.map((y) => y.id).includes(x))
      : schedulesList.map((x) => x.id).slice(0, 3);
    return scheduleIds.includes(currentScheduleId)
      ? scheduleIds
      : [currentScheduleId, ...scheduleIds];
  };

  // Get the first 3 schedules to compare.
  const [selectedSchedules, setSelectedSchedules] = useState<string[]>(getSelectedSchedules());

  useEffect(() => {
    setSelectedSchedules(getSelectedSchedules());
  }, [currentScheduleId]);

  useEffect(() => {
    const fetchSelectedSchedulesSummary = async () => {
      const allScheduleHighlights = await Promise.all(
        selectedSchedules.map((scheduleId) => getHighlightsFromSchedule(scheduleId))
      );
      setData(allScheduleHighlights);
    };
    fetchSelectedSchedulesSummary();

    if (selectedSchedules.length === 0) {
      navigate({ to: '/schedules', search: (prev) => ({ s: prev.s, t: 'compare' }) });
    } else {
      navigate({
        to: '/schedules',
        search: (prev) => ({ s: prev.s, t: 'compare', c: selectedSchedules }),
      });
    }
  }, [selectedSchedules]);

  return (
    <Stack className={classes.scheduleCompareContainer}>
      <MultiSelect
        label="Select schedules to compare"
        data={schedulesList.map((schedule) => ({ label: schedule.name, value: schedule.id }))}
        value={selectedSchedules}
        searchable
        onChange={(value) => setSelectedSchedules(value.sort((a, b) => Number(a) - Number(b)))}
        w={800}
      />
      {selectedSchedules.length ? (
        <CompareTable allScheduleHighlights={data} selectedIds={selectedSchedules} />
      ) : null}
    </Stack>
  );
}
