/* eslint-disable no-restricted-syntax */
import { useCallback, useMemo, useState } from 'react';
import { useAppStore } from 'stores/appStore';
import {
  Card,
  Button,
  Flex,
  Stack,
  Title,
  MultiSelect,
  CloseButton,
  Box,
  Portal,
} from '@mantine/core';
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'clsx';

import {
  ConstraintSlot,
  ConstraintSlotTranslations,
  TEAM_KEYS,
  TeamType,
  WEEK_KEYS,
  WeekType,
} from 'utils/scheduleConsts';

import { StadiumBlockConstraint } from 'utils/constraintConsts';
import sharedClasses from '../ScheduleEyeChart/EyeChartSharedStyles.module.css';
import classes from './StadiumBlockChart.module.css';

const columnHelper = createColumnHelper<StadiumBlockChartRow>();

const cellGenerator =
  (clickHandler: (e: React.MouseEvent<HTMLParagraphElement>) => void, isEditable: boolean) =>
  (info: CellContext<StadiumBlockChartRow, { constraintSlots: ConstraintSlot[] }>) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <p
      title={`${info.column.id} - Week ${info.row.original.week}${
        info.getValue().constraintSlots.length
          ? `: ${info
              .getValue()
              .constraintSlots.map((slot) => ConstraintSlotTranslations[slot])
              .join(', ')}`
          : ''
      }`}
      data-team={info.column.id}
      data-week={info.row.original.week}
      className={cx({
        [classes.partialBlock]: info.getValue().constraintSlots.length,
        [classes.fullBlock]: info.getValue().constraintSlots.includes(ConstraintSlot.ALL),
      })}
      {...(isEditable && {
        onClick: (e) => clickHandler(e),
      })}
    >
      {info.getValue().constraintSlots.length ? 'Ｘ' : ''}
    </p>
  );

const columnGenerator = (
  teams: TeamType[],
  clickHandler: (e: React.MouseEvent<HTMLParagraphElement>) => void,
  isEditable: boolean
) =>
  teams.map((team) =>
    columnHelper.accessor(team, {
      cell: cellGenerator(clickHandler, isEditable),
    })
  );

const columns = (
  clickHandler: (e: React.MouseEvent<HTMLParagraphElement>) => void,
  isEditable: boolean
) => [
  columnHelper.display({
    id: 'week',
    cell: (info) => <p>{info.row.original.week}</p>,
  }),
  columnHelper.group({
    header: 'NFC',
    columns: [
      columnHelper.group({
        header: 'EAST',
        columns: columnGenerator(TEAM_KEYS.slice(0, 4), clickHandler, isEditable),
      }),
      columnHelper.group({
        header: 'NORTH',
        columns: columnGenerator(TEAM_KEYS.slice(4, 8), clickHandler, isEditable),
      }),
      columnHelper.group({
        header: 'SOUTH',
        columns: columnGenerator(TEAM_KEYS.slice(8, 12), clickHandler, isEditable),
      }),
      columnHelper.group({
        header: 'WEST',
        columns: columnGenerator(TEAM_KEYS.slice(12, 16), clickHandler, isEditable),
      }),
    ],
  }),
  columnHelper.group({
    header: 'AFC',
    columns: [
      columnHelper.group({
        header: 'EAST',
        columns: columnGenerator(TEAM_KEYS.slice(16, 20), clickHandler, isEditable),
      }),
      columnHelper.group({
        header: 'NORTH',
        columns: columnGenerator(TEAM_KEYS.slice(20, 24), clickHandler, isEditable),
      }),
      columnHelper.group({
        header: 'SOUTH',
        columns: columnGenerator(TEAM_KEYS.slice(24, 28), clickHandler, isEditable),
      }),
      columnHelper.group({
        header: 'WEST',
        columns: columnGenerator(TEAM_KEYS.slice(28, 32), clickHandler, isEditable),
      }),
    ],
  }),
];

function StadiumBlockEditorDropdown({
  open,
  close,
  positionX,
  positionY,
  editingWeek,
  editingTeam,
}: {
  open: boolean;
  close: () => void;
  positionX: number;
  positionY: number;
  editingWeek: WeekType | undefined;
  editingTeam: TeamType | undefined;
}) {
  const { draftStadiumBlockConstraints, setDraftStadiumBlockConstraints } = useAppStore();
  const editCb = (constraintSlots: ConstraintSlot[]) => {
    const newConstraints = [...draftStadiumBlockConstraints];
    let updateOccured = false;
    for (const constraint of newConstraints) {
      if (constraint.week === editingWeek && constraint.team === editingTeam) {
        if (!updateOccured) {
          constraint.slot = constraintSlots;
          updateOccured = true;
        } else {
          newConstraints.splice(newConstraints.indexOf(constraint), 1);
        }
      }
    }
    if (!updateOccured) {
      newConstraints.push({
        week: editingWeek as WeekType,
        team: editingTeam as TeamType,
        slot: constraintSlots,
      });
    }
    setDraftStadiumBlockConstraints(newConstraints);
  };

  const value =
    (
      draftStadiumBlockConstraints.find(
        (constraint) => constraint.week === editingWeek && constraint.team === editingTeam
      ) as StadiumBlockConstraint | undefined
    )?.slot ?? [];

  return (
    <Card
      style={{
        position: 'absolute',
        top: positionY,
        left: positionX,
        display: open ? 'block' : 'none',
        border: '2px solid lightgray',
        zIndex: 20,
      }}
    >
      <Box w="100%" display="flex" style={{ justifyContent: 'flex-end' }}>
        <CloseButton size="sm" ml="auto" onClick={close} />
      </Box>
      <Stack>
        <Title order={5}>{`Week ${editingWeek} - ${editingTeam}`}</Title>
        <MultiSelect
          hidePickedOptions
          w="240"
          withCheckIcon={false}
          data={Object.entries(ConstraintSlotTranslations).map(([key, label]) => ({
            label,
            value: key,
            disabled:
              (key === ConstraintSlot.BLACK_FRIDAY && editingWeek !== 13) ||
              (key === ConstraintSlot.CHRISTMAS && editingWeek !== 17) ||
              (key === ConstraintSlot.TGIV && editingWeek !== 13),
          }))}
          searchable
          // @ts-ignore
          value={value}
          onChange={(slot) => {
            if (slot.includes(ConstraintSlot.ALL)) {
              editCb([ConstraintSlot.ALL]);
            } else {
              editCb(slot as ConstraintSlot[]);
            }
          }}
        />
        <Button onClick={() => editCb([])}>Clear</Button>
      </Stack>
    </Card>
  );
}

type StadiumBlockChartRow = {
  week: WeekType;
} & {
  [K in TeamType]?: {
    constraintSlots: ConstraintSlot[];
  };
};

type StadiumBlockChartIndex = StadiumBlockChartRow[];

const generateStadiumBlockIndex: (
  constraints: StadiumBlockConstraint[]
) => StadiumBlockChartIndex = (constraints) => {
  const retVal: StadiumBlockChartIndex = [];
  WEEK_KEYS.forEach((week) => {
    const temp: StadiumBlockChartRow = { week };
    TEAM_KEYS.forEach((team) => {
      const stadiumBlockConstraint = constraints.find(
        (constraint) => constraint.week === week && constraint.team === team
      );
      temp[team] = {
        constraintSlots: stadiumBlockConstraint?.slot ?? [],
      };
    });
    retVal.push(temp);
  });
  return retVal;
};

export function StadiumBlockChart({ isEditable = false }: { isEditable?: boolean }) {
  const { draftStadiumBlockConstraints, currentStadiumBlockConstraints } = useAppStore();
  const constraints = isEditable ? draftStadiumBlockConstraints : currentStadiumBlockConstraints;

  const stadiumBlockIndex = useMemo(() => generateStadiumBlockIndex(constraints), [constraints]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPositionX, setDropdownPositionX] = useState(0);
  const [dropdownPositionY, setDropdownPositionY] = useState(0);
  const [editingWeek, setEditingWeek] = useState<WeekType | undefined>(undefined);
  const [editingTeam, setEditingTeam] = useState<TeamType | undefined>(undefined);

  // TODO: Figure out why this positioning is not being applied correctly
  // when scrolling.
  const clickHandler = useCallback((e: React.MouseEvent<HTMLParagraphElement>) => {
    const { team, week } = e.currentTarget.dataset;
    if (!team || !week) {
      setDropdownOpen(false);
      return;
    }
    const targetBounds = e.currentTarget.getBoundingClientRect();
    setDropdownOpen(true);
    setEditingWeek(Number(week) as WeekType);
    setEditingTeam(team as TeamType);

    let xPosition = targetBounds.left;
    if (xPosition + 280 > window.innerWidth) {
      xPosition = window.innerWidth - 280;
    }
    setDropdownPositionX(xPosition);
    setDropdownPositionY(targetBounds.top + 25);
  }, []);

  const table = useReactTable({
    data: stadiumBlockIndex,
    columns: columns(clickHandler, isEditable),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Flex
      align="center"
      direction="column"
      gap="sm"
      className={cx([
        sharedClasses.ubiquiaEyeChartContainer,
        classes.stadiumBlockChartContainer,
        isEditable && classes.isEditable,
      ])}
    >
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Portal target={document.body}>
        <StadiumBlockEditorDropdown
          open={dropdownOpen}
          close={() => setDropdownOpen(false)}
          editingWeek={editingWeek}
          editingTeam={editingTeam}
          positionX={dropdownPositionX}
          positionY={dropdownPositionY}
        />
      </Portal>
    </Flex>
  );
}
